import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { fetchData } from "../../services/api";
import { showBar, hideBar } from "../../store/reducers/sidebar";
import MaterialsNotFound from "../Errors/MaterialsNotFound";

import Player from "../../components/Player";
import PdfViewer from "../../components/PDFViewer";
import FileViewer from "react-file-viewer";

import "./ModulePage.scss";

const ModulePage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const moduleId = searchParams.get("id");
  const modules = useSelector((state) => state.modules);

  useEffect(() => {  
    const module = modules?.modules.find((module) => module.id === Number(moduleId));
    setMaterials(module?.materials || []);
  }, [modules])

  const [materials, setMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState({});
  const [extension, setExtension] = useState("");
  const wrapper = useRef();

  const selectHandler = (e) => {
    e = e.target || e;
    if (!e || !e.id) return;

    wrapper.current.childNodes.forEach((material) => {
      if (Number(material.id) !== Number(e.id)) {
        material.classList.remove("selected");
      } else material.classList.add("selected");
    });

    const material = materials.find(
      (material) => material.material_id === Number(e.id)
    );

    const target = material.content?.split(".");
    let ext;
    if (target?.length > 0) {
      ext = target[1];
    } else {
      ext = "";
    }

    setExtension(ext);
    setSelectedMaterial(material);
  };

  const goToTest = (e) => {
    e.preventDefault();
    navigate(`/test?moduleId=${moduleId}`);
  };

  useEffect(() => {
    if (
      !materials ||
      !wrapper ||
      !wrapper.current ||
      !wrapper.current.childNodes
    ) {
      return;
    }

    selectHandler(wrapper.current.childNodes[0]);
  }, [materials]);

  useEffect(() => {
    dispatch(hideBar());

    return () => {
      dispatch(showBar());
    };
  });

  const onError = (e) => {
    return <div>Error in loading! {e.target.value}</div>;
  };

  return (
    <div className="module-page d-flex flex-row">
      {selectedMaterial && selectedMaterial.material_id && (
        <div className="module-content">
          {Number(selectedMaterial.material_type) === 1 ? (
            <div
              className="content-textarea"
              dangerouslySetInnerHTML={{ __html: selectedMaterial.content }}
              onDragStart={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
            />
          ) : Number(selectedMaterial.material_type) === 2 ? (
            <Player props={{ videoId: selectedMaterial.content }} />
          ) : Number(selectedMaterial.material_type) === 3 ? (
            extension === "pdf" ? (
              <PdfViewer
                props={{
                  filePath: `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/${selectedMaterial.content}`,
                }}
              />
            ) : extension === "doc" || extension === "docx" ? (
              <div
                onDragStart={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              >
                <FileViewer
                  fileType={extension}
                  filePath={`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/${selectedMaterial.content}`}
                  onError={onError}
                  onDragStart={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                />
              </div>
            ) : extension === "ppt" || extension === "pptx" ? (
              <div></div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      )}
      {materials.length > 0 ? (
        <div className="material-sidebar">
          <Link to="/my-courses" className="sidebar-header">
            <i className="bi bi-arrow-left" />
            <span>Обучения</span>
          </Link>
          <div className="sidebar-content">
            <div className="lessons">
              <div className="content" ref={wrapper}>
                {materials.map((material, index) => {
                  return (
                    <div
                      className="material"
                      key={index}
                      id={material.material_id}
                      onClick={selectHandler}
                    >
                      {material.material_name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <Link
            to={`/test?moduleId=${moduleId}`}
            className="card-btn btn btn-primary buttons"
          >
            Към изпит
          </Link>
        </div>
      ) : (
        materials.length !== 1 && <MaterialsNotFound />
      )}
    </div>
  );
};

export default ModulePage;
