import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../store/reducers/sidebar";

const Header = () => {
  const dispatch = useDispatch();

  return (
    <header>
      <button
        className="burger-btn d-block   position-absolute"
        onClick={() => dispatch(toggleSidebar())}
      >
        <i className="bi bi-justify fs-3"></i>
      </button>
    </header>
  );
};

export default Header;
