import { Accordion } from "react-bootstrap";

import "./QuestionsAccordion.scss";
import EditQuestionModal from "../EditQuestionModal";

const QuestionsAccordion = ({ allQuestions, onDeleteItem, onEditQuestion }) => {

  return (
    <div className="card material-items">
      <div className="card-body">
        <Accordion defaultActiveKey="0" flush>
          {allQuestions.length > 0
            ? allQuestions.map((question, idx) => (
              <Accordion.Item
                key={idx}
                eventKey={question.question_id}
              >
                <div className="accordion-header" key={idx}>
                  <Accordion.Button>
                    <i className="fas fa-align-justify fa-lg accordion-icon" />
                    <span className="ps-1 title">{question.question_title}</span>
                  </Accordion.Button>
                  <div className="right-icons" key={idx}>
                    <EditQuestionModal
                      questionData={question}
                      onSaveQuestion={onEditQuestion}
                    />
                    <i
                      className="far fa-trash-can fa-lg accordion-icon delete-icon"
                      onClick={() => onDeleteItem(question.question_id)}
                    />
                  </div>
                </div>
                <Accordion.Body>
                  <span>{question.question_description}</span>
                  <div className="answers" key={idx}>
                    {question.question_type !== 3 &&
                      question.answers?.length > 0
                      ? question.answers.map((answer, idx) => (
                        <div key={idx} className="answer">
                          <span className="title">{answer.answer_text}</span>
                          {answer.iscorrect ? (
                            <i className="fa fa-check correct" />
                          ) : (
                            <i className="fa fa-times incorrect" />
                          )}
                        </div>
                      ))
                      : <span>Помощни думи: {question.question_open_answer_help || question.hint}</span>}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))
            : ""}
        </Accordion>
      </div>
    </div>
  );
};

export default QuestionsAccordion;
