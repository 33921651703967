import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { fetchData } from "../../../services/api";
import { formatDate } from "../../../services/formatDate";

import RefferencesTable from "../../../components/Tables/RefferencesTable";
import BarChart from "../../../components/Charts/BarChart";

const Tests = () => {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function getTests() {
            try {
                const response = await fetchData(`/tests/test-ref`);

                if (response.status === 204) throw new Error();

                if (response.data.data.length > 1) {
                    const mappedData = response.data.data.map((test) => ({
                        test: test.name,
                        module: test.module_name,
                        start_date: formatDate(test.start_date),
                        numStaff: test.user_count,
                    }));
                    setTableData(mappedData);
                }
            } catch (error) {
                navigate("/references-tests");
            }
        }

        getTests();
    }, []);

    const tests = [...new Set(tableData.map((data) => data.test))];

    const usersPerTest = tableData.map((test) =>
        tableData.filter((data) => data.user_count === test.user_count).length
    );

    const barColors = ['blue', 'lightblue'];

    const chartDataBar = {
        labels: tests,
        datasets: [
            {
                label: 'Брой учащи решавали тест',
                data: usersPerTest,
                backgroundColor: barColors,
            },
        ],
    };

    const chartOptionsBar = {
        scale: {
            ticks: {
                precision: 0,
            }
        },
        y: {
            beginAtZero: true,
        },
    };

    return (
        <div className="tests-info row">
            <div className="col-12">
                <div className="tests">
                    <div className="tests-body">
                        <RefferencesTable
                            tableName="Решени тестове:"
                            tableHead={["Тест", "Модул", "Дата", "Брой служители"]}
                            tableData={tableData}
                        />
                    </div>
                    <div className="col-md-6">
                        <BarChart chartName="Брой учащи за тест"
                            chartData={chartDataBar}
                            charOptions={chartOptionsBar} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tests;