import { useState, useEffect } from "react";
import { fetchData, postData } from "../../../services/api";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import ModuleInfo from "../../../components/AddModulesBody/ModuleInfo";
import CreateMaterials from "../../../components/AddModulesBody/CreateMaterials";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "./CreateModule.scss";

const dates = () => {
  const start = new Date();
  let end = new Date();
  end.setHours(end.getHours() + 1);
  return {
    start: start,
    end: end,
  };
};

const CreateModule = () => {
  const [searchParams] = useSearchParams();
  const module_id = searchParams.get("id");
  const location = useLocation();
  const navigate = useNavigate();
  const isEdit = location.pathname === "/admin/manage-modules/edit-module";

  const [moduleData, setModuleData] = useState({
    module_name: "",
    module_description: "",
    job_titles: [],
    preview_image: "",
    start_date: dates().start,
    end_date: dates().end,
    materials: [],
  });
  const [jobRoles, setJobRoles] = useState([]);
  const [AddCourseSteps, setAddCourseSteps] = useState(1);

  // effects
  useEffect(() => {
    if (isEdit) {
      async function getModuleData() {
        try {
          const response = await fetchData(`modules/by-id?id=${module_id}`);
          if (response.status !== 201) throw new Error();
          let data = response.data.data;
          let buff = data?.job_titles;
          let formated = [];
          buff?.forEach((job) => {
            formated.push({
              key: job.id,
              label: job.job_title_name,
              value: job.job_title_name,
            });
          });
          setModuleData({
            ...data,
            job_titles: formated,
          });
        } catch (err) {
          console.log(err);
          // navigate('/admin/manage-modules')
        }
      }
      getModuleData();
    }
  }, []);

  useEffect(() => {
    async function getJobOptions() {
      try {
        const response = await fetchData("/roles/job-titles");
        if (response.status !== 201) throw new Error();
        const jobData = response.data.data.map((job) => ({
          key: job.id,
          label: job.job_title_name,
          value: job.job_title_name,
        }));
        setJobRoles(jobData);
      } catch (error) {}
    }

    getJobOptions();
  }, []);

  // methods
  const handleNextPage = () => {
    if (AddCourseSteps < 3) {
      setAddCourseSteps((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (AddCourseSteps > 1) setAddCourseSteps((prev) => prev - 1);
  };

  const handleUpdateMaterials = (newMaterials) => {
    setModuleData((moduleData) => {
      return { ...moduleData, materials: newMaterials };
    });
  };

  const handleSaveModule = async () => {
    try {
      const response = await postData("/modules/create", moduleData);
      if (response.status !== 201) throw new Error();
      Swal.fire({
        title: " Успешно запазен модул!",
        icon: "success",
        confirmButtonText: "Към начало",
      }).then(function () {
        navigate("/admin/manage-modules");
      });
    } catch (error) {
      Swal.fire({
        title: "Възникна грешка при добавянето на модула!",
        icon: "error",
        confirmButtonText: "Опитайте отново",
      });
    }
  };

  const handleSaveEditedModule = async () => {
    try {
      const response = await postData("/modules/update", moduleData);
      if (response.status !== 201) throw new Error();
      Swal.fire({
        title: " Успешно запазен модул!",
        icon: "success",
        confirmButtonText: "Към начало",
      }).then(function () {
        navigate("/admin/manage-modules");
      });
    } catch (error) {
      if(error && error.response && error.response.status === 413) {
        Swal.fire({
          title: "Някой от файловете или снимката на модула е прекалено голям!",
          icon: "error",
          confirmButtonText: "Промени",
        });
        return;
      }
      Swal.fire({
        title: "Възникна грешка при добавянето на модула!",
        icon: "error",
        confirmButtonText: "Опитайте отново",
      });
    }
  };

  const changeHandler = (name, value) => {
    setModuleData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const methods = {
    onNextPage: handleNextPage,
    onPrevPage: handlePreviousPage,
    onUpdateMaterials: handleUpdateMaterials,
    onSaveMaterials: handleSaveModule,
    onEditModule: handleSaveEditedModule,
  };

  return (
    <div className="row">
      <div className="create-module card">
        <div className="header">
          <h3>{isEdit ? "Редактиране на модул" : "Създаване на модул"}</h3>
          <ul className="steps">
            <li className={AddCourseSteps >= 1 ? `active` : ""}>Информация </li>
            <li className={AddCourseSteps >= 2 ? "active" : ""}>Материали</li>
          </ul>
        </div>
        <div className="card-body">
          {AddCourseSteps === 1 && (
            <ModuleInfo
              moduleData={moduleData}
              jobRoles={jobRoles}
              methods={methods}
              changeHandler={changeHandler}
            />
          )}
          {AddCourseSteps === 2 && (
            <CreateMaterials
              moduleData={moduleData}
              methods={methods}
              isEdit={isEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CreateModule;
