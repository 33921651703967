import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const CustomPagination = ({ currentPage, totalPages, handlePageChange }) => {
  const maxPageCount = totalPages;
  const halfMaxPageCount = Math.floor(maxPageCount / 2);

  // Calculate the start and end page numbers to display
  let startPage = currentPage - halfMaxPageCount;
  let endPage = currentPage + halfMaxPageCount;

  if (startPage < 1) {
    startPage = 1;
    endPage = Math.min(maxPageCount, totalPages);
  }

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, totalPages - maxPageCount + 1);
  }

  let isPageNumberOutOfRange;

  const pageNumbers = [...new Array(totalPages)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === startPage;
    const isPageNumberLast = pageNumber === endPage;
    const isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 1;

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          active={pageNumber === currentPage}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  return (
    <Pagination className='mb-0' max-size="5">
      <Pagination.Prev
        className='prev-next-btn'
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {pageNumbers}
      <Pagination.Next
        className='prev-next-btn'
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default CustomPagination;
