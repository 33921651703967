import { useState, useEffect } from "react";
import arrow from "../../../assets/images/arrow-up-circle-fill.svg";
import "./BackToTopButton.scss";
const BackToTopButton = () => {
  const [backToTop, setBackTotop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackTotop(true);
      } else {
        setBackTotop(false);
      }
    },[]);
  });

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {backToTop && (
        <button className="btn .btn-lg to-top-btn" onClick={scrollUp}>
          <img src={arrow} alt="btn icon" width="32px"></img>
        </button>
      )}
    </>
  );
};

export default BackToTopButton;
