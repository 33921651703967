import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import './PdfViewer.scss'

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfViewer = ({ props }) => {
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);
  const filePath = props.filePath;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadError(error) {
    setError(error);
  }

  useEffect(() => {
    return () => {
      pdfjs.GlobalWorkerOptions.workerSrc = null;
    };
  }, []);

  return (
    <div className='viewerContainer mb-4'>
      {
        !error ? (
          <Document
            file={{ url: filePath }}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            className="mb-4 w-90 h-40"
          >
            {[...Array(numPages)].map((x, i) => (
              <div className='pageContainer justify-content-between' key={i}>
                <span className='pageLabel'>Page {i + 1} of {numPages}</span>
                <Page pageNumber={i + 1} renderTextLayer={false} />
              </div>
            ))}
          </Document>
        ) : ('')
      }
    </div>
  );
}

export default PdfViewer;
