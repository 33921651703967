import { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { alertNotification } from "../../../services/notications";
import { Editor } from "@tinymce/tinymce-react";

const CreateMaterialModal = ({ saveMaterial }) => {
  const [newData, setMaterialData] = useState({
    material_id: Math.floor(Math.random() * 10000),
    material_name: "",
    material_type: "",
    content: "",
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleSaveNewMaterial = (e) => {
    e.preventDefault();

    if (Number(newData.material_type) === 3 && !newData.file) return;
    saveMaterial(newData);
    handleClose(false);
    setMaterialData({
      material_id: Math.floor(Math.random() * 10000),
      material_name: "",
      material_type: "",
      content: "",
    });
  };

  const fileSelectHandler = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ];

      if (allowedFileTypes.includes(selectedFile.type)) {
        const reader = new FileReader();

        reader.onload = (event) => {
          const content = event.target.result; // This is an ArrayBuffer
          const arrayBufferToBase64 = (arrayBuffer) => {
            const binary = [];
            const bytes = new Uint8Array(arrayBuffer);
            for (let i = 0; i < bytes.byteLength; i++) {
              binary.push(String.fromCharCode(bytes[i]));
            }
            return btoa(binary.join(""));
          };
          const base64String = arrayBufferToBase64(content);
          setMaterialData({
            ...newData,
            content: selectedFile.name.replace(/\.[^/.]+$/, ""),
            file: {
              content: base64String,
              extension: selectedFile.name.split(".").pop(),
            },
          });
        };

        reader.readAsArrayBuffer(selectedFile);
      } else {
        e.target.value = "";
        alertNotification(
          "Изберете файл със някое от следните разширения .pdf, .doc, .docx, .ppt, .pptx"
        );
      }
    }
  };

  return (
    <>
      <Button variant="primary" className="mb-3" onClick={handleShow}>
        Добави материал
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Добавяне на материал</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSaveNewMaterial}>
            <Form.Group>
              <Form.Label>Избери тип материал</Form.Label>
              <Form.Select
                className="mb-3"
                onChange={(e) =>
                  setMaterialData({
                    ...newData,
                    material_type: Number(e.target.value),
                  })
                }
                placeholder="Избери тип материал"
              >
                <option value="">Избери тип материал</option>

                <option value="1">Текст</option>
                <option value="2">Видео</option>
                <option value="3">Файл</option>
              </Form.Select>
            </Form.Group>

            {newData.material_type !== "" && (
              <Form.Group className="mb-3">
                <Form.Label>Заглавие</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Заглавие"
                  value={newData.material_name}
                  onChange={(e) =>
                    setMaterialData({
                      ...newData,
                      material_name: e.target.value.toString(),
                    })
                  }
                />
              </Form.Group>
            )}

            {newData.material_type === 1 && (
              <>
                <Editor
                  apiKey="gflyjsycm4t4gu683vmtsnpmm2l03o3p8jenocpzgekf9818"
                  onEditorChange={(newValue, editor) => {
                    setMaterialData({
                      ...newData,
                      content: newValue,
                    });
                  }}
                />
              </>
            )}

            {newData.material_type === 2 && (
              <>
                <Form.Label>Линк към видео</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Линк към видео"
                  onChange={(e) =>
                    setMaterialData({
                      ...newData,
                      content: e.target.value,
                    })
                  }
                />
              </>
            )}

            {newData.material_type === 3 && (
              <>
                <Form.Label>Избери документ</Form.Label>
                <Form.Control
                  type="file"
                  onChange={fileSelectHandler}
                  accept=".pdf, .doc, .docx, .ppt, .pptx"
                />
              </>
            )}

            <div className="d-flex justify-content-end gap-2 pt-4">
              <Button type="submit" variant="success">Запази</Button>
              <Button variant="danger" onClick={handleClose}>
                Затвори
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateMaterialModal;
