import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { fetchData } from "../../../../services/api";
import "./NotificationsCard.scss";
import { useSelector } from "react-redux";

const NotificationsCard = () => {
  const { notifications } = useSelector((state) => state.home);

  const formatDate = (date) => {
    const newDate = new Date(date);
    return (
      newDate.getDate() +
      "." +
      (newDate.getMonth() + 1) +
      "." +
      newDate.getFullYear()
    );
  };

  return (
    <div className="card notifications ">
      <div className="card-header">
        <h4 className="card-title d-flex">
          <i className="bx bx-check font-medium-5 pl-25 pr-75"></i>Известия
        </h4>
      </div>
      {notifications && notifications.length > 0 ? (
        <Link to="/my-courses" className="card-content notifications-content pb-4">
          <div className="table-responsive">
            <table className="table table-hover table-lg">
              <tbody>
                {notifications.map((notification, index) => (
                  <tr key={index}>
                    <td className="col-auto">
                      <p className=" mb-0">
                        На {formatDate(notification.start_date)} започва
                        обучението по модул "{notification.module_name}" към
                        обучение "{notification.job_title_name}".
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Link>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <tbody className="d-flex justify-content-center">
              <tr>
                <td className="col-auto">
                  <p className=" mb-0">Нямате известия</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default NotificationsCard;
