import ListGroup from "react-bootstrap/ListGroup";
import "./JobTitlesList.scss"
import { useState } from "react";

function JobTitlesList({ onClose, jobTitles, handleEdit, handleDelete }) {

  const [editingJobTitle, setEditingJobTitle] = useState(null);
  const startEditing = (jobTitle) => {
    setEditingJobTitle(jobTitle);
  };
  const saveEditing = (id, name) => {
    setEditingJobTitle(null);
    handleEdit(id, name);
  };
  const handleDeleteJob = (id) => {
    handleDelete(id);
  };

  return (
    <>
      <p className="form-group label fw-medium mt-5">
        Списък с налични длъжности:
      </p>

      <ListGroup variant="flush">
        {jobTitles.map((jobTitle, idx) => (
          <ListGroup.Item
            key={jobTitle.id}
            className="d-flex justify-content-between fw-medium ps-0 "
            style={{ fontSize: "1em" }}
          >
            {editingJobTitle === jobTitle ? (
              <div class="input-group mb-3">
                <input
                  id={jobTitle.id}
                  type="text"
                  class="form-control"
                  defaultValue={jobTitle.job_title_name}
                  placeholder={jobTitle.job_title_name}
                  aria-label={jobTitle.job_title_name}
                  aria-describedby="basic-addon2"
                  onBlur={(e) => saveEditing(jobTitle.id, e.target.value)}
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="button" onClick={onClose}>Запази</button>
                </div>
              </div>
            ) : (
              <>
                {jobTitle.job_title_name}
                <div className="d-flex gap-2">
                  <i className="bi bi-pencil-square bi-lg icon-title" onClick={() => startEditing(jobTitle)} />
                  <i className="bi bi-trash-fill bi-lg icon-title" onClick={() => handleDeleteJob(jobTitle.id)} />
                </div>
              </>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}

export default JobTitlesList;
