import { toast } from "react-toastify";

const options = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export function alertNotification(text) {
  return toast.error(text, options);
}
export function successNotification(text) {
  return toast.success(text, options);
}
