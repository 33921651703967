import jwt from 'jwt-decode' // import dependency

// Function to decode a JWT token
const getUserInfoByToken = (token) => {
  try {
    const decoded = jwt(token, process.env.REACT_APP_JWT_SECRET_KEY);
    if(!decoded) return {};
    const { exp, iat, ...user } = decoded;
    return user || {};
  } catch (error) {
    return null;
  }
};

export default getUserInfoByToken;
