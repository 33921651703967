import SearchChat from "../../components/Chat/ChatRoom/SearchChat";
import Messages from "../../components/Chat/ChatRoom/Messages";
import { Card, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { fetchData } from "../../services/api";

import createSocket from "../../services/chat";

import "./ChatRoom.scss";

import { alertNotification } from "../../services/notications";
import Cookies from "js-cookie";

const socket = createSocket();

export default function ChatRoom() {
  const [selectedRoom, setSelectedRoom] = useState(null);
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);

  const [rooms, setRooms] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);

  const setRoom = (room) => {
    setSelectedRoom(room);
  }

  useEffect(() => {
    socket.on('connect_error', (error) => {
      navigate("/");
      alertNotification("Услугата временно не работи!");
    });

    socket.io.opts.query = {
      accessToken: Cookies.get("accessToken")
    }

    socket.connect();

    return () => {
      socket.disconnect();
    }
  }, [])

  async function getAllManagers() {
    try {
      const response = await fetchData("/chat/allrooms");
      if (response.status !== 201) throw new Error();
      setAvailableRooms(response.data.data);
    } catch (err) {
      console.log(err.messages);
    }
  }

  async function getRooms() {
    try {
      const response = await fetchData("/chat/rooms");
      if (response.status !== 201) throw new Error();
      setRooms(response.data.data);
    } catch (err) {
      console.log(err.messages);
    }
  }

  useEffect(() => {
    getRooms();
    getAllManagers();
    socket.on("update", async (data) => {
      await getRooms();
      if (data && data.room_id) {
        setSelectedRoom(data.room_id);
      }
    });
  }, [selectedRoom])

  const sendMessage = (content) => {
    socket.emit("message", {
      content,
      chat_room_id: selectedRoom,
      user_id: user.id,
    })
  }

  const openNewRoom = (room) => {
    let data;
    if (user.role_id <= 3) {
      data = {
        user_id: room.id,
        manager_id: user.id,
      }
    } else {
      data = {
        user_id: user.id,
        manager_id: room.id,
      }
    }
    socket.emit("open_room", data)
  }

  return (
    <Card id="chat3" style={{ borderRadius: "15px" }}>
      <Card.Body>
        <Row>
          <SearchChat
            selectedRoom={{
              get: selectedRoom,
              set: setRoom,
            }}
            rooms={rooms}
            allRooms={availableRooms}
            openNewRoom={openNewRoom}
          />
          {
            selectedRoom && (
              <Messages
                room={selectedRoom}
                sendMessage={sendMessage}
                socket={socket}
              />
            )
          }
        </Row>
      </Card.Body>
    </Card>
  );
}
