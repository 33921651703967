import React from 'react';
import YouTube from 'react-youtube';

const Player = ({ props }) => {
    const opts = {
        width: '100%',  
        height: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            controls: 1,
            rel: 0,
            showinfo: 0,
            modestbranding: 1,
            fs: 1,
            enablejsapi: 0,
        },
    };

    return(
        <YouTube className='h-100' videoId={props.videoId} opts={opts} onReady={(e) => e.target.playVideo()} />
    )
}

export default Player;