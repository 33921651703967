import * as XLSX from "xlsx";
import FileSaver from "file-saver";

const ExportToExcel = ({ data, tableHead, tableName }) => {
    const exportToExcel = () => {
        const columnNames = tableHead;

        const firstRow = data[0];
        const dynamicColumnNames = Object.keys(firstRow);

        const exportData = data.map((row) => dynamicColumnNames.map((col) => row[col]));

        const wsData = [columnNames, ...exportData];

        const ws = XLSX.utils.aoa_to_sheet(wsData);

        const colWidths = columnNames.map((_, index) => ({
            wch:
                Math.max(
                    ...wsData.map((row) =>
                        row[index] ? row[index].toString().length : 0
                    )
                ) + 2,
        }));

        ws["!cols"] = colWidths;

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, tableName);

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const blob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(blob, tableName + fileExtension);
    };

    return (
        <button className="btn btn-success" onClick={() => exportToExcel(tableName)}>Изтегли таблицата (.xlsx)</button>
    );
}

export default ExportToExcel;