import { useEffect, useState } from "react"
import { Button } from "react-bootstrap";
import QuestionsAccordion from "./QuestionsAccordion";
import CreateQuestionModal from './CreateQuestionModal'

const CreateQuestions = ({ questionsData, methods }) => {

    const [newQuestionsData, setNewQuestionsData] = useState(questionsData);

    const handleSubmit = () => {
        methods.onUpdateQuestions(newQuestionsData);
        methods.onSubmit(newQuestionsData);
    }
    const deleteQuestion = (question_id) => {
        setNewQuestionsData(newQuestionsData.filter((question) => question.question_id !== question_id));
    }
    const saveQuestion = (question) => {
        setNewQuestionsData([
            ...newQuestionsData, 
            question
        ]);
    }

    const appendOnIndex = (array, item, index) => {
        let buff_array = array;
        buff_array = [
            ...buff_array.slice(0, index),
            item,
            ...buff_array.slice(index)
        ];
        return buff_array;
    }

    const editQuestion = (question) => {
        let index = newQuestionsData.findIndex(x => x.question_id === question.question_id)
        let newData = newQuestionsData.filter(question_ => question_.question_id !== question.question_id);
        newData = appendOnIndex(newData, question, index);
        setNewQuestionsData(newData);
    }

    return (
        <>
            <div className="form-header">
                <h4>Въпроси</h4>
            </div>
            <CreateQuestionModal
                onSaveQuestion={saveQuestion}
            />
            <QuestionsAccordion
                allQuestions={newQuestionsData}
                onDeleteItem={deleteQuestion}
                onEditQuestion={editQuestion}
            />
            <div className="form-footer">
                <Button className="form-buttons" onClick={() => {
                    methods.onUpdateQuestions(newQuestionsData)
                    methods.onPrevPage()
                }}>
                Назад
                </Button>
                <Button className="form-buttons" onClick={handleSubmit}>
                Запази
                </Button>
            </div>
        </>
    )
}

export default CreateQuestions;