import eu from "../../../../assets/images/eu-4.png";
import eu1 from "../../../../assets/images/eu1.png";
import "./MainFooter.scss"

const MainFooter = () => {
  return (
    <footer style={{ marginTop: 'auto' }}>
      <div className="main-footer footer clearfix mb-0 text-muted">
        <div className="float-start flex-row">
          <p style={{ fontSize: "12px" }}><b>Наименование на проекта:</b> Решения в областта на информационните и комуникационни технологии<br />и киберсигурността в малките и средните предприятия<br /><b>Краен получател:</b> СКАЙ ФУУД БГ ООД<br /><b>Номер на проект:</b> BG-RRP-3.005-4441-C01<br/><b>Обща стойност:</b> 18 000лв., от които 18 000лв. безвъзмездно финансиране.<br/><b>Начало:</b> 02.06.2023г.<br/><b>Край:</b> 02.06.2024г.</p>
        </div>
        <div className="d-flex flex-row">
          <img src={eu} alt="EU" style={{ height: "8vh" }} />
          <img src={eu1} alt="EU" style={{ height: "8vh" }} />
        </div>
      </div>
    </footer >
  )
}


export default MainFooter;