import { useEffect, useState } from "react";
import CustomPagination from "../../ui/CustomPagination";
import { Form, Button } from "react-bootstrap";
import { formatDate } from "../../../services/formatDate";

const ManageTestsTable = (props) => {
  const [query, setQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const tests = props.tests;
  const tableHead = props.tableHead;

  const [filteredData, setFilteredData] = useState(tests);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);

  useEffect(() => {
    const filteredTests = tests?.filter((test) => test.name?.includes(query));

    setFilteredData(filteredTests);
    setCurrentPage(1);
  }, [query, tests]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPage = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Тестове</h5>
      </div>
      <div className="card-body">
        <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
          <div className="dataTable-top">
            <div className="dataTable-dropdown">
              <Form.Select
                id="itemsPerPage"
                className="dataTable-selector form-select"
                name="itemsPerPage"
                onChange={handleItemsPerPage}
              >
                <option value="5">5</option>
                <option value="8">8</option>
                <option value="10">10</option>
              </Form.Select>
              <label htmlFor="itemsPerPage">реда в таблица</label>
            </div>
            <div className="dataTable-search">
              <input
                className="dataTable-input"
                name="dataTable-input"
                placeholder="Търси..."
                type="text"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="dataTable-container">
            <table className="table table-striped" id="table1">
              <thead>
                <tr>
                  {tableHead?.map((data) => (
                    <th key={Math.random()}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentUsers?.map((test, _idx) => {
                  return (
                    test && (
                      <tr key={_idx}>
                        <td>{test.name}</td>
                        <td>{formatDate(test.start_date)}</td>
                        <td>{formatDate(test.end_date)}</td>
                        <td className="d-flex gap-1">
                          <Button
                            variant="success"
                            size="sm"
                            className="border-0"
                            onClick={() => props.onEdit(test.test_id)}
                          >
                            <i className="bi bi-pencil-square" /> Промени
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            className="border-0"
                            onClick={() => props.onDelete(test.test_id)}
                          >
                            <i className="bi bi-pencil-square" /> Изтрий
                          </Button>
                        </td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="dataTable-bottom">
            <nav className="dataTable-pagination">
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
              />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageTestsTable;
