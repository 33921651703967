import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const ProgressChart = (props) => {
    return (
        <div class="card w-50 h-50 d-flex justify-content-center">
            <div class="card-header w-100 h-100 d-flex justify-content-center">
                <h4 class="card-title">{props.chartName}</h4>
            </div>
            <div class="card-body w-100 h-100 d-flex justify-content-center">
                <Doughnut
                    data={props.chartData}
                    options={props.chartOprions}
                />
            </div>
        </div>
    );
};

export default ProgressChart;