import { Link, useLocation } from "react-router-dom";


const SubmenuItem = (props) => {
  const location = useLocation().pathname;

  //check if user is in this url
  let isActive = location === props.link ? "active" : ""
  return (
    <div className={`submenu-item ${isActive}`}>
      <Link to={props.link} className="sidebar-link">
        <i className={`bi bi-${props.icon}`}></i>
        <span>{props.text}</span>
      </Link>
    </div>
  );
};

export default SubmenuItem;
