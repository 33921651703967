import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { fetchData } from "../../../services/api";
import RefferencesTable from "../../../components/Tables/RefferencesTable"
import BarChart from "../../../components/Charts/BarChart";


const Materials = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getMaterials() {
      try {
        const response = await fetchData(`/materials/materials-modules`);

        if (response.status === 204) throw new Error();

        if (response.data.data.length > 1) {
          const mappedData = response.data.data.map((material) => ({
            material: material.material_name,
            module: material.module,
          }));
          setTableData(mappedData);
        } else {
          setTableData(response.data.data);
        }
      } catch (error) {
        navigate("/references-materials");
      }
    }

    getMaterials();
  }, []);

  const modules = [...new Set(tableData.map((data) => data.module))];

  const materialsPerModule = modules.map((module) =>
    tableData.filter((data) => data.module === module).length
  );

  const colours = ['lightblue', 'blue'];

  const chartData = {
    labels: modules,
    datasets: [
      {
        label: 'Материали по модули',
        data: materialsPerModule,
        backgroundColor: colours,
        borderColor: colours,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        stacked: true,
      },
    },
  };

  return (
    <div className="material-info row">
      <div className="col-12">
        <div className="materials">
          <div className="materials-body">
            <RefferencesTable
              tableName="Използвани материали:"
              tableHead={["Материали", "Модул"]}
              tableData={tableData}
            />
          </div>
          <div className="col-md-6">
            <BarChart chartName="Брой материали по модули"
              chartData={chartData}
              charOptions={chartOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Materials;