import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";

import { fetchData } from "../../../services/api";

import { Form } from "react-bootstrap";

import "./TestResult.scss";

const TestResult = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const testId = searchParams.get("testId");
    const resultId = searchParams.get("resultId");

    const [testData, setTestData] = useState({});
    const [testQuestions, setTestQuestions] = useState({});

    const [currentQuestion, setCurrentQuestion] = useState(-1);

    useEffect(() => {
        async function getTestResult() {
            try {
                const response = await fetchData(
                    `/tests/result?testId=${testId}${resultId ? `&resultId=${resultId}` : ""
                    }`
                );
                if (response.status !== 201 || !response.data || !response.data.data) {
                    throw new Error();
                }

                const testData = response.data.data;

                setTestData({
                    test_id: testData.test_id,
                    result_date: testData.result_date,
                });
                setTestQuestions(testData.questions_result);
                setCurrentQuestion(0);
            } catch (error) {
                navigate("/my-courses");
            }
        }

        getTestResult();
    }, []);

    const getAnswerClass = (answerId) => {
        const answer =
            testQuestions[currentQuestion].user_answer.includes(answerId);
        return answer ? " selected" : "";
    };

    const changePage = (next) => {
        const newPage = next ? currentQuestion + 1 : currentQuestion - 1;
        if (newPage >= 0 && newPage < testQuestions.length) {
            setCurrentQuestion(newPage);
        }
    };

    return (
        <div className="container test-result-page">
            {testQuestions && testQuestions[currentQuestion] ? (
                <div className="content">
                    <div className="question-header">
                        <div className="question-title-header">
                            <span className="question-title">
                                {testQuestions[currentQuestion].question_title}
                            </span>
                            <span className="question-type">
                                {testQuestions[currentQuestion].question_type === 1
                                    ? "Един верен отговор"
                                    : testQuestions[currentQuestion].question_type === 2
                                        ? "Няколко верни отговора"
                                        : "Отворен отговор"}
                            </span>
                        </div>
                        <span className="question-description">
                            {testQuestions[currentQuestion].question_description}
                        </span>
                    </div>
                    <div className="answers">
                        {testQuestions[currentQuestion].question_type === 3 ? (
                            <div className="text-answer">
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    readOnly
                                    value={testQuestions[currentQuestion].user_answer}
                                />
                                <span className="grade">
                                    Резултат:{" "}
                                    {testQuestions[currentQuestion].score >= 0
                                        ? testQuestions[currentQuestion].score + " точки"
                                        : "Няма"}
                                </span>
                            </div>
                        ) : (
                            testQuestions[currentQuestion].answers.map((answer) => (
                                <div
                                    className={"answer" + getAnswerClass(answer.answer_id)}
                                    key={answer.answer_id}
                                    id={answer.answer_id}
                                >
                                    <div className="answer-text">{answer.answer_text}</div>
                                    {answer.iscorrect ? (
                                        <i className="fa fa-check correct" />
                                    ) : (
                                        <i className="fa fa-times incorrect" />
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                    <div className="actions">
                        <button
                            className="btn btn-primary"
                            disabled={currentQuestion === 0}
                            onClick={() => changePage(false)}
                        >
                            Предишен
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={currentQuestion === testQuestions.length - 1}
                            onClick={() => changePage(true)}
                        >
                            Следващ
                        </button>
                        {currentQuestion === testQuestions.length - 1 ? (
                            <Link to="/">
                                <button className="btn btn-success">Начало</button>
                            </Link>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default TestResult;
