import { useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";

import { alertNotification } from "../../../services/notications";
import { Editor } from "@tinymce/tinymce-react";

const EditMaterial = ({ onClose, material, onEditMaterial, materialId }) => {
  const [newData, setMaterialData] = useState(material);
  const contentRef = useRef(newData.content);

  const types = {
    1: "Текст",
    2: "Видео",
    3: "PDF",
  };

  const handleSaveNewMaterial = (e) => {
    e.preventDefault();
    // if (Number(newData.material_type) === 3 && !newData.file) return;
    onEditMaterial(newData);
    onClose();
  };

  const fileSelectHandler = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ];

      if (allowedFileTypes.includes(selectedFile.type)) {
        const reader = new FileReader();

        reader.onload = (event) => {
          const content = event.target.result; // This is an ArrayBuffer
          const arrayBufferToBase64 = (arrayBuffer) => {
            const binary = [];
            const bytes = new Uint8Array(arrayBuffer);
            for (let i = 0; i < bytes.byteLength; i++) {
              binary.push(String.fromCharCode(bytes[i]));
            }
            return btoa(binary.join(""));
          };
          const base64String = arrayBufferToBase64(content);
          setMaterialData({
            ...newData,
            content: selectedFile.name.replace(/\.[^/.]+$/, ""),
            file: {
              content: base64String,
              extension: selectedFile.name.split(".").pop(),
            },
          });
        };

        reader.readAsArrayBuffer(selectedFile);
      } else {
        e.target.value = "";
        alertNotification(
          "Изберете файл със някое от следните разширения .pdf, .doc, .docx, .ppt, .pptx"
        );
      }
    }
  };

  const selectedMaterial = (type) => {
    if (type === 1) return "Текст";
    if (type === 2) return "Видео";
    if (type === 3) return "PDF";
  };

  const selected = selectedMaterial(newData.material_type);

  return (
    <Form onSubmit={handleSaveNewMaterial}>
      <Form.Group>
        <Form.Label>Избери тип материал</Form.Label>
        <Form.Select
          className="mb-3"
          onChange={(e) =>
            setMaterialData({
              ...newData,
              material_type: Number(e.target.value),
            })
          }
          placeholder="Избери тип материал"
        >
          <option value={selected}>{selected}</option>
          {Object.keys(types).map(
            (key) =>
              Number(key) !== newData.material_type && (
                <option key={key} value={key}>
                  {types[key]}
                </option>
              )
          )}
        </Form.Select>
      </Form.Group>

      {newData.material_type !== "" && (
        <Form.Group className="mb-3">
          <Form.Label>Заглавие</Form.Label>
          <Form.Control
            type="text"
            placeholder="Заглавие"
            value={newData.material_name}
            onChange={(e) =>
              setMaterialData({
                ...newData,
                material_name: e.target.value.toString(),
              })
            }
          />
        </Form.Group>
      )}

      {newData.material_type === 1 && (
        <>
          <Form.Label>Текст</Form.Label>
          <Editor
            apiKey="gflyjsycm4t4gu683vmtsnpmm2l03o3p8jenocpzgekf9818"
            onEditorChange={(newValue, editor) => {
              contentRef.current = newValue;
            }}
            onBlur={() => {
              setMaterialData({
                ...newData,
                content: contentRef.current,
              });
            }}
            initialValue={
              newData.content !== "" ? newData.content : "Въведете текст..."
            }
          />
        </>
      )}

      {newData.material_type === 2 && (
        <>
          <Form.Label>Линк към видео</Form.Label>
          <Form.Control
            type="text"
            placeholder="Линк към видео"
            defaultValue={material.content}
            onChange={(e) =>
              setMaterialData({
                ...newData,
                content: e.target.value,
              })
            }
          />
        </>
      )}

      {newData.material_type === 3 && (
        <>
          <Form.Label>Избери документ</Form.Label>
          <Form.Control
            type="file"
            onChange={fileSelectHandler}
            accept=".pdf, .doc, .docx, .ppt, .pptx"
          />
        </>
      )}

      <div className="d-flex justify-content-end gap-2 pt-4">
        <Button type="submit" variant="success">
          Запази
        </Button>
        <Button variant="danger" onClick={onClose}>
          Затвори
        </Button>
      </div>
    </Form>
  );
};

export default EditMaterial;
