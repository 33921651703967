import { Button, Form } from "react-bootstrap";
import { postData } from "../../../services/api";
import {
  successNotification,
  alertNotification,
} from "../../../services/notications";
import { useState } from "react";

const AddUserForm = ({ onClose, roles, setUsers, job_titles }) => {
  const [data, setData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "",
    job_title: "",
  });

  const [validated, setValidated] = useState(false);
  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSaveUserClick = async (newUser) => {
    try {
      const response = await postData(`/users/addUser`, {
        ...newUser,
      });
      onClose();
      successNotification(response.data.message);
      setUsers(response.data.data);
    } catch (error) {
      if (!error.response) {
        return;
      }

      alertNotification(error.response.data.message);
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    //check if the form has all the required fields
    form.checkValidity() || setValidated(true);

    //if the form is valid, call the next function
    if (form.checkValidity() === true) {
      handleSaveUserClick(data);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {/*Name*/}
      <Form.Group className="name form-group">
        <Form.Label htmlFor="first_name">Име</Form.Label>
        <Form.Control
          required
          type="text"
          name="first_name"
          id="first_name"
          placeholder="Име"
          value={data.first_name}
          onChange={changeHandler}
          autoComplete="off"
        />
        <Form.Control.Feedback type="invalid">
          Добавете имe!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Middlename*/}
      <Form.Group className="middle-name form-group">
        <Form.Label htmlFor="middle_name">Презиме</Form.Label>
        <Form.Control
          required
          type="text"
          name="middle_name"
          id="middle_name"
          value={data.middle_name}
          placeholder="Презиме"
          onChange={changeHandler}
        />
        <Form.Control.Feedback type="invalid">
          Добавете Презиме!
        </Form.Control.Feedback>
      </Form.Group>

      {/*LastName*/}
      <Form.Group className="last-name form-group">
        <Form.Label htmlFor="last_name">Фамилия</Form.Label>
        <Form.Control
          required
          type="text"
          name="last_name"
          id="last_name"
          value={data.last_name}
          placeholder="Фамилия"
          onChange={changeHandler}
        />
        <Form.Control.Feedback type="invalid">
          Добавете Фамилия!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Email*/}
      <Form.Group className="email form-group">
        <Form.Label htmlFor="email">Имейл</Form.Label>
        <Form.Control
          required
          type="email"
          name="email"
          id="email"
          value={data.email}
          placeholder="Имейл"
          onChange={changeHandler}
          autoComplete="off"
        />
        <Form.Control.Feedback type="invalid">
          Добавете Имейл!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Passowrd*/}
      <Form.Group className="password form-group">
        <Form.Label htmlFor="password">Парола</Form.Label>
        <Form.Control
          required
          type="password"
          name="password"
          id="password"
          value={data.password}
          placeholder="Парола"
          onChange={changeHandler}
		  autocomplete="new-password"
        />
        <Form.Control.Feedback type="invalid">
          Добавете Парола!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Role*/}
      <Form.Group className="role form-group">
        <Form.Label htmlFor="role">Роля</Form.Label>
        <Form.Select
          required
          name="role"
          id="role"
          onChange={(e) => { setData({ ...data, role: e.target.value }); if (data.role !== "staff member") { setData({ ...data, role: e.target.value, job_title: "" }); } }}
          value={data.role}
        >
          <option value="">Изберете роля</option>
          {roles.map((role, _idx) => (
            <option key={role.role_name} value={role.role_name}>
              {role.role_name}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Изберете роля!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Job title*/}
      {(data?.role === "Staff member") ? (
        <Form.Group className="job_title form-group">
          <Form.Label htmlFor="job_title">Длъжност</Form.Label>
          <Form.Select
            required
            name="job_title"
            id="job_title"
            onChange={(e) => setData({ ...data, job_title: e.target.value })}
            value={data.job_title}
          >
            <option value="">Изберете длъжност</option>
            {job_titles.map((currentJobTitle) => (
              <option
                value={currentJobTitle.job_title_name}
                key={currentJobTitle.id}
              >
                {currentJobTitle.job_title_name}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Добавете длъжност!
          </Form.Control.Feedback>
        </Form.Group>
      ) : ("")}

      {/*Buttons*/}
      <div className="d-flex justify-content-end gap-2 pt-4">
        <Button type="submit" variant="success">Запази</Button>
        <Button variant="danger" onClick={onClose}>
          Затвори
        </Button>
      </div>
    </Form>
  );
};

export default AddUserForm;
