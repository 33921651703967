import { Link } from "react-router-dom";
import errorImg from "../../../assets/images/no-modules.webp";
import { hideBar, showBar } from "../../../store/reducers/sidebar";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
const MaterialsNotFound = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideBar());

    return () => {
      dispatch(showBar());
    }
  })
  return (
    <div className="error-page container">
      <div className="col-md-8 col-12 offset-md-2">
        <div className="text-center">
          <img
            className="img-error"
            height="600vh"
            src={errorImg}
            alt="Not Found"
          />
          <p className="fs-5 text-gray-600">
            Няма намерени материали. Моля, свържете се с администратора.
          </p>
          <Link to="/" className="btn btn-lg btn-primary mt-3">
            Към начало
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MaterialsNotFound;
