import { useSelector } from "react-redux";

import ProfileCard from "../../components/ui/Card-Components/ProfileCard";
import ModulesTableCard from "../../components/ui/Card-Components/ModulesTableCard";
import NotificationsCard from "../../components/ui/Card-Components/NotificationsCard";
import HeadingCard from "../../components/ui/Card-Components/HeadingCard";

import "./HomePage.scss";

const HomePage = () => {
  //user info
  const { user } = useSelector((state) => state.user);
  const { cards_info: cardsInfo } = useSelector((state) => state.home);

  let isStaff = user?.role_name.toLowerCase() === "staff member" ? true : false;


  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = date + "." + month + "." + year;

  return (
    <div className="">
      <div className="page-heading">
        <h3>Начало</h3>
      </div>
      <div className="page-content">
        <section className="row">
          <div className="col-12 col-lg-9 left-side">
            <div className="row small-cards">
              {cardsInfo && (
                <>
                  <HeadingCard
                    icon="bi bi-file-person-fill"
                    color="purple"
                    title="Длъжност"
                    text={
                      user?.role_name === "Staff member"
                        ? user?.job_title_name
                        : user?.role_name
                    }
                  />
                  <HeadingCard
                    icon="clipboard2-check-fill"
                    color="blue"
                    title="Изпити"
                    text={cardsInfo.test_count + "" || "Няма"}
                  />
                  {isStaff ? (
                    <HeadingCard
                      icon="bi bi-mortarboard-fill"
                      color="green"
                      title="Oценка"
                      text={cardsInfo.text}
                    />
                  ) : (
                    <HeadingCard
                      icon="bi bi-calendar-fill"
                      color="red"
                      title="Дата"
                      text={currentDate}
                    />
                  )}
                </>
              )}
            </div>
            <ModulesTableCard />
          </div>
          <div className="col-12 col-lg-3 right-side ">
            <ProfileCard userInfo={user} />
            <NotificationsCard />
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
