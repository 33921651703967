import React, { useState } from "react";
import "./ModuleInfo.scss";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";

import { alertNotification } from "../../../services/notications";

const ModuleInfo = ({ moduleData, methods, jobRoles, changeHandler }) => {
  const navigate = useNavigate();
  // JavaScript

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    //check if the form has all the required fields
    form.checkValidity() || setValidated(true);

    //if the form is valid, call the next function
    if (form.checkValidity() === true) {
      methods.onNextPage();
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Navigates back one step in the history
  };

  const fileSelectHandler = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/jpeg", "image/png"];

      if (allowedFileTypes.includes(selectedFile.type)) {
        const reader = new FileReader();

        reader.onload = (event) => {
          const content = event.target.result; // This is an ArrayBuffer
          const arrayBufferToBase64 = (arrayBuffer) => {
            const binary = [];
            const bytes = new Uint8Array(arrayBuffer);
            for (let i = 0; i < bytes.byteLength; i++) {
              binary.push(String.fromCharCode(bytes[i]));
            }
            return btoa(binary.join(""));
          };
          const base64String = arrayBufferToBase64(content);

          const obj = {
            content: selectedFile.name.replace(/\.[^/.]+$/, ""),
            file: {
              content: base64String,
              extension: selectedFile.name.split(".").pop(),
            },
          };

          changeHandler("preview_image", obj);
        };

        reader.readAsArrayBuffer(selectedFile);
      } else {
        e.target.value = "";
        alertNotification(
          "Изберете файл със някое от следните разширения .png .jpeg .jpg"
        );
      }
    }
  };

  return (
    <>
      <div className="form-header">
        <h4>Информация</h4>
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="module-name form-group">
          <Form.Label htmlFor="module_name">Име на модул*</Form.Label>
          <Form.Control
            required
            type="text"
            name="module_name"
            id="module_name"
            placeholder="Име на модул"
            defaultValue={moduleData?.module_name}
            onChange={(e) => {
              changeHandler(e.target.name, e.target.value);
            }}
          />
          <Form.Control.Feedback type="invalid">
            Добавете име на модул!
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="file">Качи снимка</Form.Label>
          <Form.Control
            type="file"
            id="file"
            onChange={fileSelectHandler}
            accept={[".png", ".jpeg", ".jpg"]}
          />
        </Form.Group>
        <Form.Group className="role form-group">
          <Form.Label>Длъжност на модул</Form.Label>
          <Select
            options={jobRoles}
            name="job_titles"
            value={moduleData?.job_titles}
            onChange={(selectedJobs) =>
              changeHandler("job_titles", selectedJobs)
            }
            isMulti={true}
          />
        </Form.Group>
        <Form.Group className="form-group ">
          <Form.Label htmlFor="start_date">Начална дата на модул</Form.Label>
          <Flatpickr
            name="start_date"
            id="start_date"
            className="form-control"
            value={moduleData?.start_date}
            onChange={(date) => changeHandler("start_date", date[0])}
            options={{
              enableTime: true,
              dateFormat: "d/m/Y H:i  ",
              time_24hr: true,
            }}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label htmlFor="end_date">Крайна дата на модул</Form.Label>
          <Flatpickr
            className={"form-control"}
            name="end_date"
            id="end_date"
            value={moduleData?.end_date}
            onChange={(date) => changeHandler("end_date", date[0])}
            options={{
              enableTime: true,
              dateFormat: "d/m/Y H:i  ",
              time_24hr: true,
            }}
          />
          {/* {!areDatesValid() && (
            <span className="error-label">
              *Крайната дата не може да бъде по-малка или равна на началната
            </span>
          )} */}
        </Form.Group>
        <div className="form-footer">
          <Button className="form-buttons" onClick={handleGoBack}>
            Назад
          </Button>
          <Button className="form-buttons" type="submit">
            Напред
          </Button>
        </div>
      </Form>
    </>
  );
};
export default ModuleInfo;
