import io from 'socket.io-client';
import Cookies from 'js-cookie';

const SOCKET_SERVER_URL = `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_SOCKET_PORT}`;

const socket = () => {
  const socket_instance = io(SOCKET_SERVER_URL, { 
    autoConnect: true,
    transports: ['websocket'],
    upgrade: false,
    timeout: 5000
  });

  return socket_instance;
};

export default socket;