import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { fetchData } from '../../../../services/api'
import { useSelector } from "react-redux";

const ModulesTableCard = () => {
  const { landing_info: info } = useSelector((state) => state.home);

  const getModuleStatus = (module) => {
    if (module.start_date && module.end_date) {
      const currentDate = new Date();
      const startDate = new Date(module.start_date);
      const endDate = new Date(module.end_date);

      if (currentDate >= startDate && currentDate <= endDate) {
        return { className: "success", text: 'Активен' };
      } else if (currentDate < startDate) {
        return { className: "warning", text: 'Предстоящ' };
      } else {
        return { className: "secondary", text: 'Приключил' };
      }
    }
  }

  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.getDate() + '.' + (newDate.getMonth() + 1) + '.' + newDate.getFullYear();
  }

  const isTestActive = (start_date, end_date) => {
    if (!start_date) return false;
    if (!start_date && !end_date) return false;

    start_date = new Date(start_date);

    if (end_date) {
      end_date = new Date(end_date);
      const currentDate = new Date();

      if (currentDate >= start_date && currentDate <= end_date) {
        return true;
      }
    } else {
      const currentDate = new Date();

      if (currentDate >= start_date) {
        return true;
      }
    }
  }

  return (
    <div className="module-info row">
      <div className="col-12">
        <div className="card overflow-auto ">
          <div className="card-header">
            <h5 className="card-title">Информация за модули</h5>
          </div>
          <div className="card-body">
            <table className="table table-striped" id="table1">
              <thead>
                <tr>
                  <th>Модул</th>
                  <th>Дата на изпит</th>
                  <th>Дата на консултация</th>
                  <th>Статус</th>
                </tr>
              </thead>
              <tbody>
                {
                  (info && info.length > 0) && info.map((course) => {
                    return (
                      course.module_info && course.module_info.map((module) => {
                        return (
                          <tr key={module.module_id}>
                            <td>{module.module_name}</td>
                            <td>
                              {
                                isTestActive(module.test_start_date, module.test_end_date) ? (
                                  <Link to={"/test?moduleId=" + module.module_id} className="btn btn-success badge">
                                    {formatDate(module.test_start_date)} {module.test_end_date && ("- " + formatDate(module.test_end_date))}
                                  </Link>
                                ) : (
                                  <span>{formatDate(module.test_start_date)} {module.test_end_date && ("- " + formatDate(module.test_end_date))}</span>
                                )
                              }
                            </td>
                            <td>N/A</td>
                            <td>
                              <span className={"badge bg-" + (getModuleStatus(module).className)}>{getModuleStatus(module).text}</span>
                            </td>
                            <td>
                              <Link to={"/module?id=" + module.module_id} className="btn btn-primary badge">
                                Отвори
                              </Link>
                            </td>
                          </tr>
                        )
                      })
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModulesTableCard;
