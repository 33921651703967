import { MDBIcon } from "mdb-react-ui-kit";
import { useState } from "react";


const Footer = ({ sendMessageEvent }) => {
  const [content, setContent] = useState("");

  const sendMessage = () => {
    if (!content) return;
    setContent("");
    sendMessageEvent(content);
  }

  return (
    <div className="text-muted d-flex justify-content-start align-items-center pe-3 mt-2 input-div">
      <input
        type="text"
        className="form-control form-control-lg"
        placeholder="Напиши съобщение"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            sendMessage();
          }
        }}
      />
      <a className="ms-3 send-btn" onClick={(e) => {
        e.preventDefault();
        sendMessage();
      }}>
        <MDBIcon fas icon="paper-plane" />
      </a>
    </div>
  )
}

export default Footer