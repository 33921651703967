import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../services/api";
import { formatDate } from "../../../services/formatDate";

import DataTable from "../../../components/Tables/RefferencesTable";
import BarChart from "../../../components/Charts/BarChart";

const Grades = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    async function getModules() {
      try {
        const response = await fetchData('/users/references-results');
        if (response.status === 204) throw new Error();

        if (response.data.data.length > 1) {
          const mappedData = response.data.data.map((userModules) => ({
            name: userModules.first_name + ' ' + userModules.last_name,
            module: userModules.module_name,
            start_date: formatDate(userModules.start_date),
            score: userModules.score || "Няма оценка",
          }));
          setTableData(mappedData);
        } else {
          setTableData(response.data.data);
        }
      } catch (error) {
        navigate('/references-grades');
      }
    }

    getModules();
  }, []);

  const modules = [...new Set(tableData.map((data) => data.module))];

  const usersPerModule = modules.map((module) =>
    tableData.filter((data) => data.module === module).length
  );

  const barColors = ['blue', 'lightblue'];

  const chartDataBar = {
    labels: modules,
    datasets: [
      {
        label: 'Брой учащи в модул',
        data: usersPerModule,
        backgroundColor: barColors,
      },
    ],
  };

  const chartOptionsBar = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="grade-info row" >
      <div className="col-12">
        <div className="grade">
          <div className="grade-body">
            <DataTable
              tableName="Оценки служители:"
              tableHead={["Име", "Модул", "Дата на изпит", "Оценка"]}
              tableData={tableData}
            />
          </div>
          <div className="charts row fit-content">
            <div className="col-md-6">
              <BarChart chartName="Оценки на служители"
                chartData={chartDataBar}
                charOptions={chartOptionsBar} />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Grades;
