import { useState, useEffect } from "react";

import { Form, Button } from "react-bootstrap";
import { postData, patchData } from "../../../services/api";
import {
  successNotification,
  alertNotification,
} from "../../../services/notications";
import JobTitlesList from "../../JobTitlesList/JobTitlesList";

const AddJobTitleForm = ({ onClose, jobTitles }) => {
  const [newJobTitle, setNewJobTitlte] = useState({
    job_title_name: "",
  });

  const [validated, setValidated] = useState(false);

  const handleAddJobTitle = async (newJobTitle) => {
    try {
      await postData("/roles/add-job-title", { job_title_name: newJobTitle });
      onClose();
      successNotification("Успешно добавихте нова длъжност!");
    } catch (error) {
      alertNotification(error.response.data.message);
      if (!error.response) {
        return;
      }
    }
  };

  const handleUpdateJob = async (jobTitleId, newJobTitle) => {
    try {
      await postData("/roles/update-job-title", { job_title_id: jobTitleId, job_title_name: newJobTitle });
      onClose();
      successNotification("Успешно променихте длъжността!");
    } catch (error) {
      alertNotification(error.response.data.message);
      if (!error.response) {
        return;
      }
    }
  };
  const handleDeleteJob = async (jobTitleId) => {
    try {
      await patchData("/roles/delete-job-title", { job_title_id: jobTitleId });
      onClose();
      successNotification("Успешно изтрихте длъжността!");
    } catch (error) {
      alertNotification("Моля, уверете се, че длъжността няма служители и модули свързани с нея!");
      if (!error.response) {
        return;
      }
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    //check if the form has all the required fields
    form.checkValidity() || setValidated(true);

    //if the form is valid, call the next function
    if (form.checkValidity() === true) {
      handleAddJobTitle(newJobTitle);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="name form-group">
        <Form.Label htmlFor="job_title_name">Въведете име на длъжността</Form.Label>
        <Form.Control
          required
          type="text"
          name="job_title_name"
          id="job_title_name"
          placeholder="Длъжност"
          onChange={(e) => setNewJobTitlte(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Моля добавете има на длъжност!
        </Form.Control.Feedback>
      </Form.Group>

      <JobTitlesList onClose={onClose} jobTitles={jobTitles} handleEdit={handleUpdateJob} handleDelete={handleDeleteJob} />

      <div className="d-flex justify-content-end gap-2 pt-4">
        <Button type="submit" variant="success">Запази</Button>
        <Button variant="danger" onClick={onClose}>
          Затвори
        </Button>
      </div>
    </Form>
  );
};

export default AddJobTitleForm;
