import { Button, Form } from "react-bootstrap";
import { postData } from "../../../services/api";
import {
  successNotification,
  alertNotification,
} from "../../../services/notications";
import { useState } from "react";

const EditUserForm = ({
  currentUserData,
  onClose,
  roles,
  job_titles,
  onChangeUser,
}) => {
  const [data, setData] = useState({
    first_name: currentUserData.first_name,
    middle_name: currentUserData.middle_name,
    last_name: currentUserData.last_name,
    email: currentUserData.email,
    job_title: currentUserData.job_title_name,
    password: currentUserData.password,
    role_name: currentUserData.role_name,
    status: currentUserData.status,
  });

  const [validated, setValidated] = useState(false);

  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    //check if the form has all the required fields
    form.checkValidity() || setValidated(true);

    //if the form is valid, call the next function
    if (form.checkValidity() === true) {
      (async () => {
        try {
          const response = await postData(`/users/update`, {
            ...data,
            id: currentUserData.id,
          });

          onChangeUser(response.data.data);
          onClose();
          successNotification(response.data.message);
        } catch (error) {
          if (!error.response) {
            return;
          }

          alertNotification(error.response.data.message);
        }
      })();
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {/*Name*/}
      <Form.Group className="name form-group">
        <Form.Label htmlFor="first_name">Име</Form.Label>
        <Form.Control
          required
          type="text"
          name="first_name"
          id="first_name"
          placeholder="Име"
          value={data.first_name}
          onChange={changeHandler}
          autoComplete="off"
        />
        <Form.Control.Feedback type="invalid">
          Добавете имe!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Middlename*/}
      <Form.Group className="middle-name form-group">
        <Form.Label htmlFor="middle_name">Презиме</Form.Label>
        <Form.Control
          required
          type="text"
          name="middle_name"
          id="middle_name"
          value={data.middle_name}
          placeholder="Презиме"
          onChange={changeHandler}
        />
        <Form.Control.Feedback type="invalid">
          Добавете Презиме!
        </Form.Control.Feedback>
      </Form.Group>

      {/*LastName*/}
      <Form.Group className="last-name form-group">
        <Form.Label htmlFor="last_name">Фамилия</Form.Label>
        <Form.Control
          required
          type="text"
          name="last_name"
          id="last_name"
          value={data.last_name}
          placeholder="Фамилия"
          onChange={changeHandler}
        />
        <Form.Control.Feedback type="invalid">
          Добавете Фамилия!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Email*/}
      <Form.Group className="email form-group">
        <Form.Label htmlFor="email">Имейл</Form.Label>
        <Form.Control
          required
          type="email"
          name="email"
          id="email"
          value={data.email}
          placeholder="Имейл"
          onChange={changeHandler}
          autoComplete="off"
        />
        <Form.Control.Feedback type="invalid">
          Добавете Имейл!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Password*/}
      <Form.Group className="password form-group">
        <Form.Label htmlFor="password">Парола</Form.Label>
        <Form.Control
          type="password"
          name="password"
          id="password"
          defaultValue={data.password}
          value={data.password}
          placeholder="Парола"
          onChange={changeHandler}
          autoComplete="off"
        />
        <Form.Control.Feedback type="invalid">
          Добавете Парола!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Role*/}
      <Form.Group className="role form-group">
        <Form.Label htmlFor="role_name">Роля</Form.Label>
        <Form.Select
          name="role_name"
          id="role_name"
          onChange={(e) => {
            setData({ ...data, role_name: e.target.value });
            if (data.role_name !== "staff member") {
              setData({ ...data, role_name: e.target.value, job_title: "" });
            }
          }}
        >
          <option value={data.role_name}>{data.role_name}</option>
          {roles
            .filter((currentRole) => currentRole.role_name !== data.role_name)
            .map((currentRole) => (
              <option key={currentRole.role_name} value={currentRole.role_name}>
                {currentRole.role_name}
              </option>
            ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Изберете роля!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Job title*/}
      {data?.role_name === "Staff member" && (
        <Form.Group className="job_title form-group">
          <Form.Label htmlFor="job_title">Длъжност</Form.Label>
          <Form.Select name="job_title" id="job_title" onChange={changeHandler}>
            <option value={data.job_title}>{data.job_title}</option>
            {job_titles
              .filter(
                (currentJobTitle) =>
                  currentJobTitle.job_title_name !== data.job_title
              )
              .map((currentJobTitle) => (
                <option
                  value={currentJobTitle.job_title_name}
                  key={currentJobTitle.job_title_name}
                >
                  {currentJobTitle.job_title_name}
                </option>
              ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Добавете длъжност!
          </Form.Control.Feedback>
        </Form.Group>
      )}

      {/* Status */}
      <Form.Group className="status form-group">
        <Form.Label htmlFor="status">Статус</Form.Label>
        <Form.Select name="status" id="status" onChange={changeHandler}>
          <option value={data.status}>{data.status}</option>
          {data.status === "Активен" ? (
            <option value="Неактивен" key={"Неактивен"}>
              Неактивен
            </option>
          ) : (
            <option value="Активен" key={"Активен"}>
              Активен
            </option>
          )}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Изберете статус!
        </Form.Control.Feedback>
      </Form.Group>

      {/*Buttons*/}
      <div className="d-flex justify-content-end gap-2 pt-4">
        <Button type="submit" variant="success">
          Запази
        </Button>
        <Button variant="danger" onClick={onClose}>
          Затвори
        </Button>
      </div>
    </Form>
  );
};

export default EditUserForm;
