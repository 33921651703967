import React, { useState, useEffect } from "react";

import Cookies from "js-cookie";
import { fetchData } from "../../../services/api";
import { patchData } from "../../../services/api";
import { successNotification } from "../../../services/notications";

import ManageUsersTable from "../../../components/Tables/ManageUsersTable";
import AddJobTitleForm from "../../../components/Forms/AddJobTitleForm";
import FormsModal from "../../../components/Modals/FormsModal";
import AddUserForm from "../../../components/Forms/AddUserForm/AddUserForm";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";
import JobTitlesList from "../../../components/JobTitlesList/JobTitlesList";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddRole, setAddRole] = useState(false);

  useEffect(() => {
    async function getRoles() {
      try {
        const response = await fetchData("/roles/");
        setRoles(response.data.data);
      } catch (error) {
        if (!error.response) {
          return;
        }
      }
    }

    getRoles();
  }, []);

  async function getJobTitles() {
    try {
      const response = await fetchData("/roles/job-titles");
      setJobTitles(response.data.data);
    } catch (error) {
      if (!error.response) {
        return;
      }
    }
  }

  useEffect(() => {
    getJobTitles();
  }, []);

  useEffect(() => {
    async function getUsers() {
      try {
        const response = await fetchData("/users/");
        const users = response.data.data;
        setUsers(users);
      } catch (error) {
        if (!error.response) {
          return;
        }
      }
    }
    if (Cookies.get("accessToken")) getUsers();
  }, []);

  const handleDelete = (email) => {
    Swal.fire({
      title: "Сигурни ли сте?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Отказ",
      confirmButtonText: "Изтрий",
    }).then((res) => {
      if (res.isConfirmed) {
        async function updateUserDeleteStatus() {
          try {
            const response = await patchData(`/users`, { userEmail: email });
            const users = response.data.data;
            setUsers(users);
            successNotification("Успешно изтрихте потребител");

          } catch (error) {
            if (!error.response) {
              return;
            }
          }
        }
        if (Cookies.get("accessToken")) updateUserDeleteStatus();
      }
    });
  };


  //add user modal
  const handleCloseAddUser = () => setShowAddUser(false);
  const handleShowAddUser = () => setShowAddUser(true);

  //add role modal
  const handleCloseAddRole = () => {
    setAddRole(false);
    getJobTitles();
  }
  const handleShowAddrole = () => setAddRole(true);

  return (
    <div className=" mt-5 pb-2">
      <div>
        {/* add user modal */}
        <FormsModal
          title={"Добави потребител"}
          show={showAddUser}
          onClose={handleCloseAddUser}
          onShow={handleShowAddUser}
        >
          <AddUserForm
            onClose={handleCloseAddUser}
            roles={roles}
            job_titles={jobTitles}
            setUsers={setUsers}
          />
        </FormsModal>

        {/*add job title */}
        <FormsModal
          title={"Добави длъжност"}
          show={showAddRole}
          onClose={handleCloseAddRole}
          onShow={handleShowAddrole}
        >
          <AddJobTitleForm onClose={handleCloseAddRole} jobTitles={jobTitles}/>
        </FormsModal>
      </div>

      <ManageUsersTable
        tableName="Потребители:"
        users={users}
        roles={roles}
        job_titles={jobTitles}
        onDelete={handleDelete}
        onChangeUser={setUsers}
        tableHead={[
          "Име",
          "Презиме",
          "Фамилия",
          "Имейл",
          "Длъжност",
          "Статус",
          "Роля",
          "Промени/Изтрий",
        ]}
      />
    </div>
  );
};

export default ManageUsers;
