import "./HeadingCard.scss";

const HeadingCard = (props) => {
  return (
    <div className="col-6 col-lg-4 col-md-6">
      <div className="card">
        <div className="card-body px-4 py-4-5">
          <div className="row">
            <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
              <div className={`stats-icon ${props.color}`}>
                <i className={`icon bi bi-${props.icon}`}></i>
              </div>
            </div>
            <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
              <h6 className="text-muted font-semibold">{props.title}</h6>
              <h6 className="font-extrabold mb-0">{props.text}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadingCard;
