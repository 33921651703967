const routes = [
    {
        route: '/admin/manage-users',
        permission: [
            'admin',
        ]
    },
    {
        route: '/admin/manage-modules',
        permission: [
            'admin'
        ]
    },
    {
        route: '/admin/manage-tests',
        permission: [
            'admin'
        ]
    },
    {
        route: '/admin/manage-tests/add',
        permission: [
            'admin'
        ]
    },
    {
        route: '/admin/manage-tests/edit',
        permission: [
            'admin'
        ]
    },
    {
        route: '/admin/manage-modules/create-module',
        permission: [
            'admin'
        ]
    },
    {
        route: '/admin/manage-modules/edit-module',
        permission: [
            'admin'
        ]
    },
    {
        route: '/results',
        permission: [
            'admin',
            'staff member',
            'manager',
            'visitor'
        ]
    },
    {
        route: "/references-grades",
        permission: [
            "admin",
            "visitor"
        ]
    },
    {
        route: "/references-staff-members",
        permission: [
            "admin",
            "visitor"
        ]
    },
    {
        route: "/references-materials",
        permission: [
            "admin",
            "visitor"
        ]
    },
    {
        route: "/references-tests",
        permission: [
            "admin",
            "visitor"
        ]
    },
    {
        route: "/references-study-progress",
        permission: [
            "admin",
            "visitor"
        ]
    },
    {
        route: "/chat",
        permission: [
            "admin",
            "manager",
            "staff member"
        ]
    },
    {
        route: "/test/evaluate",
        permission: [
            "admin",
            "manager"
        ]
    },
]

/*
{
    route: 'example',
    public: false,
    permission: [
        'admin',
        'manager'
    ]
}
{
    route: 'example',
    public: true
}
*/

export const hasPermission = (path, role_name) => {
    const route = routes.find(x => x.route === path)
    if (!route) return true;
    if (route.public) return true;
    if (!route.permission) return false;
    if (route.permission.includes(role_name.toLowerCase())) return true;
    return false;
}