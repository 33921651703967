import { useSelector } from "react-redux";

import Form from 'react-bootstrap/Form'

import './ProfileInfo.scss'

const Profile = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <div className="container mainContainer">
      <Form className="form profileInfoForm">
        <Form.Label className="profileInfoLabel">Информация за профила</Form.Label>
        <div className="infoGroups">
          <Form.Group>
            <Form.Label>Име</Form.Label>
            <Form.Control type="text" disabled value={user ? user.first_name : ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Презиме</Form.Label>
            <Form.Control type="text" disabled value={user ? user.middle_name : ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Фамилия</Form.Label>
            <Form.Control type="text" disabled value={user ? user.last_name : ''} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" disabled value={user ? user.email : ''} />
          </Form.Group>
        </div>
      </Form>
    </div>
  );
};

export default Profile;
