import RefferencesTable from "../../../components/Tables/RefferencesTable/RefferencesTable";
import BarChart from "../../../components/Charts/BarChart/BarChart";
import RadarChart from "../../../components/Charts/RadarChart";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { fetchData } from "../../../services/api";
import { formatDate } from "../../../services/formatDate";

const EmployeeView = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Оценка по модул',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    async function getStaffGrade() {
      try {
        const response = await fetchData(`/users/employee-results`);

        if (response.status === 204) throw new Error();
        if (response.data.data.length > 1) {
          const mappedData = response.data.data.map((module) => ({
            module: module.module_name,
            end_date: formatDate(module.result_date) || "Предстояща",
            score: module.score || "Предстояща",
          }));
          setTableData(mappedData);

          const chartLabels = mappedData.map((data) => data.module);
          const chartDataValues = mappedData.map((data) => data.score);

          setChartData({
            ...chartData,
            labels: chartLabels,
            datasets: [
              {
                ...chartData.datasets[0],
                data: chartDataValues,
              },
            ],
          });

        } else {
          setTableData(response.data.data);
        }
      } catch (error) {
        navigate("/results");
      }
    }

    getStaffGrade();
  }, []);

  return (
    <div>
      <h5>
        Моите оценки
      </h5>
      <RefferencesTable
        tableName="Оценки от модули:"
        tableHead={["Модул", "Дата", "Оценка"]}
        tableData={tableData}
      />
      <div className="charts row">
        <div className="col-md-6">
          <BarChart chartData={chartData} />
        </div>
        <div className="col-md-6">
          <RadarChart chartData={chartData} />
        </div>
      </div>
    </div>
  );
};

export default EmployeeView;
