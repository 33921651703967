// userSlice.js
import Cookies from 'js-cookie';
import getUserInfoByToken from '../../services/user';
import { createSlice } from '@reduxjs/toolkit';

const getInitialUser = () => {
  const oneTime = Cookies.get("oneTime") || false;
  const accessToken = Cookies.get("accessToken") || null;
  if (accessToken && !oneTime) {
    return getUserInfoByToken(accessToken);
  } else if (accessToken && oneTime) {
    Cookies.remove("accessToken");
    Cookies.remove("oneTime");
    return null;
  } else {
    return null;
  }
};

const manageCookies = (info) => {
  Cookies.set("accessToken", info.accessToken, {
    expires: 1 / 24,
  });

  if (!info.remember) {
    Cookies.set("oneTime", true, { expires: 1 / 24 });
    Cookies.remove("email");
  } else {
    Cookies.set("email", info.email);
  }
}

const clearCookies = () => {
  Cookies.remove("accessToken");
  if(Cookies.get("oneTime")) {
    Cookies.remove("oneTime");
    Cookies.remove("email");
  }
}

const initialUser = getInitialUser();

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: initialUser,
    isAuthenticated: true,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      manageCookies(action.payload);
      state.isAuthenticated = true;
    },
    logout: (state) => {
      clearCookies();
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export const { login, updateUserInfo, logout } = userSlice.actions;
export default userSlice.reducer;
