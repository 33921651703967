import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { fetchData } from "../../../services/api";

import RefferencesTable from "../../../components/Tables/RefferencesTable";
import ProgressChart from "../../../components/Charts/ProgressChart";

const StaffProgress = () => {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function getProgress() {
            try {
                const response = await fetchData(`/users/references-progress`);

                if (response.status === 204) throw new Error();

                if (response.data.data.length > 1) {
                    const mappedData = response.data.data.map((user) => ({
                        userId: user.id,
                        name: (user.first_name + " " + user.last_name),
                        module: user.notPassedModulesTestsUpcoming || "Няма",///passed modules and tests
                        test: user.passedModulesTests || "Няма",///upcoming modules and tests
                        email: user.missedModulesTests || "Няма",
                        score: user.passedCount || 0,
                        numStaff: user.notPassedCountUpcoming || 0,
                        comment: user.missedCount || 0,
                    }));
                    setTableData(mappedData);
                } else {
                    setTableData(response.data.data);
                }
            } catch (error) {
                navigate("/references-study-progress");
            }
        }

        getProgress();
    }, []);

    const [selectedUser, setSelectedUser] = useState(null);

    const handleUserSelect = (userId) => {
        setSelectedUser(userId);
    };

    const selectedUserData = tableData.find((user) => user.userId === selectedUser);

    const passedModules = selectedUserData ? selectedUserData.score : 0;
    const upcomingModules = selectedUserData ? selectedUserData.numStaff : 0;
    const notPassedModules = selectedUserData ? selectedUserData.comment : 0;
    const passedTests = selectedUserData ? selectedUserData.score : 0;
    const upcomingTests = selectedUserData ? selectedUserData.numStaff : 0;
    const notPassedTests = selectedUserData ? selectedUserData.comment : 0;

    const totalModules = passedModules + upcomingModules + notPassedModules;
    const totalTests = passedTests + upcomingTests + notPassedTests;
    const moduleProgress = (passedModules / totalModules) * 100;
    const testProgress = (passedTests / totalTests) * 100;
    const totalProgress = (moduleProgress + testProgress) / 2;

    const getColor = (progress) => {
        if (progress <= 20) {
            return 'rgba(255, 0, 0, 0.6)';
        } else if (progress <= 50) {
            return 'rgba(255, 165, 0, 0.6)';
        } else {
            return 'rgba(0, 128, 0, 0.6)';
        }
    };

    const chartData = {
        labels: ["Прогрес на учащия се"],
        datasets: [
            {
                data: [totalProgress, 100 - totalProgress],
                backgroundColor: [getColor(totalProgress), getColor(totalProgress)],
            },
        ],
    };

    const chartOptions = {
        cutout: '80%',
        animation: {
            animateRotate: true,
            animateScale: true,
        },
        legend: {
            display: true,
            position: 'bottom',
        },
        tooltips: {
            enabled: true,
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    const data = tableData.map((user) => {
        const { userId, name, module, test, email } = user;
        return {
            userId,
            name,
            module,
            test,
            email,
        };
    });

    return (
        <div className="staff-progress-info row">
            <div className="col-12">
                <div className="staff-progress">
                    <div className="staff-progress-body">
                        <RefferencesTable
                            tableName="Развитие на служителя:"
                            tableHead={[
                                'Име',
                                'Преминали модули',
                                'Оставащи модули',
                                'Пропуснати модули',
                            ]}
                            tableData={data}
                            selectedUser={handleUserSelect}
                        />
                    </div>
                    <div className="d-flex justify-content-center">
                        {selectedUser !== null && (
                            <ProgressChart
                                chartName="Прогрес на учащия"
                                chartData={chartData}
                                chartOptions={chartOptions}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StaffProgress;