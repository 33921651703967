import profileImg from "../../../../assets/images/2.webp";

const ProfileCard = (props) => {
  const user = props.userInfo;

  return (
    <div className="card profile-info">
      <div className="card-body py-4 px-4">
        <div className="d-flex align-items-center">
          <div className="avatar avatar-xl">
            <img src={profileImg} alt="Face 1" />
          </div>
          <div className="ms-3 name">
            <h5 className="font-bold">
              {user?.first_name} {user?.last_name}
            </h5>
            <h6 className="text-muted mb-0">{user?.role_name}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
