import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = (props) => {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">{props.chartName}</h4>
      </div>
      <div className="card-body">
        <Bar data={props.chartData} options={props.chartOprions} />
      </div>
    </div>
  );
};

export default BarChart;
