import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchData } from '../../../services/api';

const getGrade = (grade) => {
    let text;
    if (grade) {
      if (grade <= 6 && grade >= 5.5) {
        text = "Отличен";
      } else if (grade < 5.5 && grade >= 4.5) {
        text = "Много добър";
      } else if (grade < 4.5 && grade >= 3.5) {
        text = "Добър";
      } else if (grade < 3.5 && grade >= 3) {
        text = "Среден";
      } else if (grade < 3 && grade >= 2) {
        text = "Слаб";
      } else if (grade < 2 && grade >= 0) {
        text = "Слаб";
      } else {
        text = "Няма оценка";
      }
    } else {
      text = "Няма оценка";
    }
    return text;
  };

// Async thunk actions
export const fetchCardsInfo = createAsyncThunk(
  'home/fetchCardsInfo',
  async () => {
    const response = await fetchData("modules/cards-info");
    if (response.status !== 201) throw new Error(response.data.message);
    let data = response.data.data;
    data.text = getGrade(data.grade);
    return data;
  }
);

export const fetchNotifications = createAsyncThunk(
  'home/fetchNotifications',
  async () => {
    const response = await fetchData("/modules/notifications");
    if (response.status !== 201) throw new Error(response.data.message);
    return response.data.data;
  }
);

export const fetchLandingInfo = createAsyncThunk(
  'home/fetchLandingInfo',
  async () => {
    const response = await fetchData("/modules/landingInfo");
    if (response.status !== 201) throw new Error(response.data.message);
    return response.data.data;
  }
);

const homeSlice = createSlice({
  name: 'home',
  initialState: { landing_info: {}, cards_info: {}, notifications: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCardsInfo.fulfilled, (state, action) => {
        state.cards_info = action.payload;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })
      .addCase(fetchLandingInfo.fulfilled, (state, action) => {
        state.landing_info = action.payload;
      });
  }
});

export default homeSlice.reducer;