import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function FormsModal({
  show,
  onShow,
  onClose,
  title,
  isIcon = false,
  isTable = false,
  children,
}) {
  return (
    <>
      {!isTable && !isIcon && (
        <Button variant="success" onClick={onShow} className="mb-3 me-3">
          {title}
        </Button>
      )}

      {isIcon && (
        <i
          className="far fa-pen-to-square me-1 fa-lg accordion-icon"
          onClick={onShow}
        />
      )}

      {isTable && (
        <Button
          variant="warning"
          size="sm"
          className="border-0"
          onClick={onShow}
        >
          <i className="bi bi-pencil-square" /> Редактиране
        </Button>
      )}

      <Modal show={show} onHide={onClose} backdrop="static" keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}

export default FormsModal;
