import { useEffect, useState } from "react";
import ManageTestsTable from "../../../components/Tables/ManageTestsTable";

import { fetchData, patchData } from "../../../services/api";

import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2/dist/sweetalert2.js';

const ManageTests = () => {
    const [tests, setTests] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function getTests() {
            try {
                const result = await fetchData("/tests/all");

                if (result.status !== 201) throw new Error();
                setTests(result.data.data);
            } catch (err) { }
        }

        getTests();
    }, []);

    const deleteTest = async (testId) => {
        const confirmed = await Swal.fire({
            title: "Сигурни ли сте?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Отказ",
            confirmButtonText: "Изтрий",
          });
          if (confirmed.isConfirmed) {
            try {
                const result = await patchData("/tests", { test_id: testId });

                if (result.status !== 201) throw new Error();
    
                setTests(result.data.data);
            } catch (error) {
              Swal.fire({
                title: "Error!",
                text: "Failed to delete test",
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          }
    };

    const editTest = (testId) => {
        navigate(`/admin/manage-tests/edit?test_id=${testId}`)
    };

    return (
        <div>
            <div>
                <Link
                    to="/admin/manage-tests/add"
                    className="btn btn-primary mb-3 me-3 btn-success"
                >
                    Добави тест
                </Link>
            </div>
            <ManageTestsTable
                tests={tests}
                tableHead={["Име", "Начална дата", "Крайна дата", "Промени/Изтрий"]}
                onDelete={deleteTest}
                onEdit={editTest}
            />
        </div>
    );
};

export default ManageTests;
