import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchData } from '../../../services/api';

// Async thunk action
export const fetchModules = createAsyncThunk(
  'modules/fetchModules',
  async (_, { getState }) => {
    const { user } = getState().user;
    const modulesList = await fetchData(`/modules?userId=${user.id}`);
    if (modulesList.data.message !== "OK") return;
    return modulesList.data.data; // return the data
  }
);

const modulesSlice = createSlice({
  name: 'modules',
  initialState: {
    modules: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchModules.fulfilled, (state, action) => {
      state.modules = action.payload;
    });
  }
});

export default modulesSlice.reducer;