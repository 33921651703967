import { Accordion } from "react-bootstrap";
import { useState } from "react";
import EditMaterialModal from "../Modals/EditMaterialModal";
import "./MaterialsAccordion.scss";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const MaterialsAccordion = ({ allMaterials, onDeleteItem, onEditMaterial }) => {

  const handleDeleteMaterial = (id) => {
    Swal.fire({
      title: "Сигурни ли сте?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Отказ",
      confirmButtonText: "Изтрий",
    }).then((res) => {
      if (res.isConfirmed) onDeleteItem(id);
    });
  };

  return (
    <div className="card material-items">
      <div className="card-body">
        <Accordion defaultActiveKey="0" flush>
          {allMaterials.map((material, id) => (
            <Accordion.Item key={id} eventKey={id}>
              <div className="accordion-header">
                <Accordion.Button>
                  <i className="fas fa-align-justify fa-lg accordion-icon" />
                  <span className="title"> {material.material_name}</span>
                </Accordion.Button>
                <div className="right-icons">
                  <EditMaterialModal
                    material={material}
                    onEditMaterial={onEditMaterial}
                    materialId={id}
                  />
                  <i
                    className="far fa-trash-can fa-lg accordion-icon delete-icon"
                    onClick={() => handleDeleteMaterial(material.material_id)}
                  />
                </div>
              </div>
              <Accordion.Body>
                {material.material_type === 1 ? (
                  <div dangerouslySetInnerHTML={{ __html: material.content }} />
                ) : (
                  material.content
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default MaterialsAccordion;
