
import Modal from 'react-bootstrap/Modal';
import { CircularProgressbar } from 'react-circular-progressbar';

import './ResultModal.scss'

import 'react-circular-progressbar/dist/styles.css';
import { Link } from 'react-router-dom';

const ResultModal = ({ show, close, result }) => {

    return(
        <Modal show={show} onHide={close}>
            <div className='test-result'>
                <Modal.Header closeButton>
                <Modal.Title>Резултат от тест</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className='result-info'>
                        <CircularProgressbar value={result.avg_test_score} text={`${result.avg_test_score}т`} />
                        <div className='text-info'>
                            <span>Дата: {new Date(result.result_date).toLocaleString()}</span>
                            <span>Брой въпроси: {result.total_questions}</span>
                            <span>Верни отовори: {result.correct_questions}</span>
                            <span>Грешни отовори: {result.incorrect_questions}</span>
                            <span>Отворени отовори: {result.not_reviewed_questions}</span>
                        </div>
                    </div>
                    <div className='actions'>
                        <Link className='btn btn-primary view-btn' to={`/test/result?testId=${result.test_id}&resultId=${result.test_result_id}`}>Прегледай теста</Link>
                        <Link className='btn btn-primary view-btn' to={`/`}>Начало</Link>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default ResultModal;