import { useSelector } from "react-redux";
import "./Results.scss";

import ManagerView from "./ManagerView";
import EmployeeView from "./EmployeeView";

const Results = () => {
   const { user } = useSelector(state => state.user);
   let isNotAdmin = user?.role_name?.toLowerCase() === "staff member" || user?.role_name?.toLowerCase() === "visitor" ? true : false;
   return (
      <>
         {isNotAdmin ? <EmployeeView /> : <ManagerView />}
      </>
   );
}

export default Results