import React from "react";
import { Radar } from "react-chartjs-2";

const RadarChart = (props) => {
    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{props.chartName}</h4>
            </div>
            <div className="card-body">
                <Radar data={props.chartData} options={props.chartOprions} />
            </div>
        </div>
    );
};

export default RadarChart;
