import "./ResultsTable.scss";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

import { formatDate } from "../../../services/formatDate";

const ResultsTable = ({ head, data }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h5>Оценки на служители и проверка на тест</h5>
      </div>
      <div className="card-body">
        <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
          <div className="dataTable-container mt-3">
            <div className="card material-items">
              <div className="card-body">
                {data.map((user, idx) => {
                  return (
                    <Accordion defaultActiveKey="0" flush key={user.user_id}>
                      <Accordion.Item eventKey={user.user_id}>
                        <div className="accordion-header">
                          <Accordion.Button
                            name={`accordion-button-${user.user_id}`}
                          >
                            {user.first_name} {user.last_name}
                          </Accordion.Button>
                        </div>
                        <Accordion.Body className="p-4">
                          <table className="table table-striped" id="table1">
                            <thead>
                              <tr key={user.user_id}>
                                {head.map((head_element, idx) => (
                                  <th key={idx}>{head_element}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {user.test_results.map((test_result, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{test_result.module_name}</td>
                                    <td>
                                      {test_result.evaluated
                                        ? "Оценен"
                                        : "Неоценен"}
                                    </td>
                                    <td>{formatDate(test_result.evaluated)}</td>
                                    <td>{test_result.grade || "Предстояща"}</td>
                                    <td>
                                      {test_result.evaluated ? (
                                        <span className="btn btn-secondary">
                                          Проверен
                                        </span>
                                      ) : (
                                        <Link
                                          to={`/test/evaluate?testResultId=${test_result.test_result_id}`}
                                          className="btn btn-primary"
                                        >
                                          Провери
                                        </Link>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsTable;
