export function formatDate(date) {
    if (!date) {
        return 'Няма дата';
    }
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) {
        try {
            const complexDate = new Date(date);
            const formattedDate = complexDate.toLocaleString('bg-BG', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', });
            return formattedDate;
        } catch (error) {
            return 'Невалидна дата';
        }
    }
    const formattedDate = parsedDate.toLocaleString('bg-BG', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', });
    return formattedDate;
}
