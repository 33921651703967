import React, { useState, useEffect } from "react";

import CustomPagination from "../../ui/CustomPagination";
import Button from "react-bootstrap/Button";
import EditUserModal from "../../Modals/EditModal";
import { Form } from "react-bootstrap";

import "./ManageUsersTable.scss";

const ManageUsersTable = ({
  tableName,
  tableHead,
  users,
  roles,
  onDelete,
  job_titles,
  onChangeUser,
}) => {
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const [filteredData, setFilteredData] = useState(users);

  //pagination constants
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);

  useEffect(() => {
    const filteredUsers = users.filter(
      (data) =>
        data.first_name?.includes(query) ||
        data.last_name?.includes(query) ||
        data.email?.includes(query) ||
        data.job_title_name?.includes(query) ||
        data.status?.includes(query) ||
        data.role_name?.includes(query) ||
        data.middle_name?.includes(query) ||
        data.id?.includes(query)
    );

    setFilteredData(filteredUsers);
    setCurrentPage(1);
  }, [query, users]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleItemsPerPage = (e) => {
    setitemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">{tableName}</h5>
      </div>

      <div className="card-body">
        <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
          <div className="dataTable-top">
            <div className="dataTable-dropdown">
              <Form.Select
                id="itemsPerPage"
                className="dataTable-selector form-select"
                name="itemsPerPage"
                onChange={handleItemsPerPage}
              >
                <option value="5">5</option>
                <option value="8">8</option>
                <option value="10">10</option>
              </Form.Select>
              <label htmlFor="itemsPerPage">реда в таблица</label>
            </div>
            <div className="dataTable-search">
              <input
                name="dataTable-input"
                className="dataTable-input"
                placeholder="Търси..."
                type="text"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="dataTable-container">
            <table className="table table-striped" id="table1">
              <thead>
                <tr>
                  {tableHead?.map((data) => (
                    <th key={Math.random()}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentUsers?.map((person, _idx) => {
                  return (
                    person && (
                      <tr key={_idx}>
                        <td> {person.first_name}</td>
                        <td>{person.middle_name}</td>
                        <td>{person.last_name}</td>
                        <td>{person.email}</td>
                        <td>{person.job_title_name}</td>
                        <td>{person.status}</td>
                        <td>{person.role_name}</td>
                        <td className="d-flex gap-1">
                          <EditUserModal
                            currentUserData={person}
                            roles={roles}
                            job_titles={job_titles}
                            onChangeUser={onChangeUser}
                          />
                          <Button
                            variant="danger"
                            size="sm"
                            className="border-0"
                            onClick={() => onDelete(person.email)}
                          >
                            <i className="bi bi-trash-fill" /> Изтрий
                          </Button>
                        </td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="dataTable-bottom">
            <nav className="dataTable-pagination">
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUsersTable;
