import { useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import './EditQuestionModal.scss'

const EditQuestionModal = ({ questionData, onSaveQuestion }) => {
    const [show, setShow] = useState(false);
    const [questionType, setQuestionType] = useState(Number(questionData.question_type));

    const [question, setQuestion] = useState({ ...questionData })
    const answers_div = useRef(0);

    const validate = () => {
        let valid = true;
        if (!question.question_title) valid = false;
        if (questionType !== 3) {
            if (question.answers.length === 0) valid = false;
            let corrects = 0;
            question.answers.forEach((answer) => {
                if (!answer.answer_text || answer.answer_text.trim() === '') valid = false;
                if (answer.iscorrect === 1) corrects++;
            })
            if ((questionType === 1 && corrects === 0) || (questionType === 2 && corrects <= 1)) valid = false;
        }
        return valid;
    }

    const handleSave = () => {
        if (!validate()) return;
        onSaveQuestion({
            ...question,
            question_type: questionType
        });
        setShow(false);
    };

    const typeChange = (e) => {
        if (!e.target.value) return;
        setQuestionType(Number(e.target.value))
        if (!answers_div.current) return;
        let answers_buff = question.answers;
        answers_buff.forEach((answer) => {
            answer.iscorrect = 0;
        });
        setQuestion({
            ...question,
            answers: answers_buff
        })
        answers_div.current.childNodes.forEach((element) => {
            const check = element.querySelector('.form-check-input');
            check.checked = false;
        })
    }

    const answerTextChange = (e) => {
        const value = e.target.value;
        const id = e.target.parentElement.id;
        let answers_buff = question.answers;
        let idx = answers_buff.findIndex(answer => answer.answer_id === Number(id))
        answers_buff[idx] = {
            ...answers_buff[idx],
            answer_text: value
        }
        setQuestion({
            ...question,
            answers: answers_buff
        })
    }

    const correctAnswerChange = (e) => {
        const id = e.target.parentElement.parentElement.id;
        const answers_div = e.target.parentElement.parentElement.parentElement;
        let answers_buff = question.answers;
        if (questionType === 1) {
            answers_div.childNodes.forEach(element => {
                if (Number(element.id) !== Number(id)) {
                    const check = element.querySelector('.form-check-input');
                    check.checked = false;
                }
            });
            answers_buff.forEach((answer) => {
                answer.iscorrect = 0;
            });
        }
        let idx = answers_buff.findIndex(answer => answer.answer_id === Number(id))
        answers_buff[idx].iscorrect = e.target.checked ? 1 : 0;
        setQuestion({
            ...question,
            answers: answers_buff
        })
    }

    const hideModal = () => {
        setShow(false);
    }

    const addAnswer = () => {
        let answers_buff = question.answers;
        answers_buff.push({
            answer_id: Math.random(),
            answer_text: '',
            iscorrect: 0
        })
        setQuestion({
            ...question,
            answers: answers_buff
        })
    }

    const deleteAnswer = (e) => {
        const id = e.target.id;
        const answers_buff = question.answers.filter(answer => Number(answer.answer_id) !== Number(id))
        setQuestion({
            ...question,
            answers: answers_buff
        })
    }

    return (
        <>
            <i
                className="far fa-pen-to-square me-1 fa-lg accordion-icon"
                onClick={() => setShow(true)}
            />
            <Modal show={show} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Добави въпрос</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Select onChange={typeChange} defaultValue={questionData.question_type}>
                        <option hidden={true}>Избери тип въпрос</option>
                        <option value="1">Един верен отговор</option>
                        <option value="2">Много верни отговори</option>
                        <option value="3">Отворен отговор</option>
                    </Form.Select>
                    {
                        questionType !== -1 ? (
                            <div className="add-question-modal-body">
                                <Form.Label>Заглавие на въпроса</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Въведете заглавие"
                                    defaultValue={question.question_title}
                                    onChange={(e) =>
                                        setQuestion({
                                            ...question,
                                            question_title: e.target.value
                                        })
                                    }
                                />
                                <Form.Label>Описание на въпроса</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Въведете описание"
                                    defaultValue={question.question_description}
                                    onChange={(e) =>
                                        setQuestion({
                                            ...question,
                                            question_description: e.target.value
                                        })
                                    }
                                />
                                {
                                    questionType === 3 ? (
                                        <>
                                            <Form.Label>Помощни думи</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Помощни думи"
                                                defaultValue={question.question_open_answer_help || question.hint}
                                                onChange={(e) => {
                                                    setQuestion({
                                                        ...question,
                                                        question_open_answer_help: e.target.value
                                                    })
                                                }}
                                            />
                                        </>
                                    ) : ''
                                }
                                {
                                    questionType === 1 || questionType === 2 ? (
                                        <div className="add-question-answers" ref={answers_div}>
                                            {
                                                question.answers.map((answer) => (
                                                    <div key={answer.answer_id} className="add-question-answer" id={answer.answer_id}>
                                                        <Form.Check
                                                            type={questionType === 1 ? 'radio' : questionType === 2 ? 'checkbox' : ''}
                                                            defaultChecked={answer.iscorrect === 1}
                                                            onChange={correctAnswerChange}
                                                        />
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Въведете отговор"
                                                            defaultValue={answer.answer_text}
                                                            onChange={answerTextChange}
                                                        />
                                                        <Button variant="danger" id={answer.answer_id} onClick={deleteAnswer}>
                                                            Изтрий
                                                        </Button>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ) : ''
                                }
                            </div>
                        ) : ''
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        (questionType === 1 || questionType === 2) &&
                        <Button variant="success" onClick={addAnswer}>
                            + Добави отговор
                        </Button>
                    }
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Затвори
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Запази
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditQuestionModal;