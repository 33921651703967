import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import CustomPagination from "../../ui/CustomPagination";
import ExportToExcel from "../../ui/ExportToExcel";
import "./RefferencesTable.scss";

const RefferencesTable = (props) => {
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);
  const data = props.tableData;
  const tableHead = props.tableHead;
  const tableName = props.tableName;
  const { selectedUser = () => {} } = props;
  const [filteredData, setFilteredData] = useState(data);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const location = useLocation().pathname;
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const filteredData = data?.filter(
      (data) => 
        data.name?.toString().includes(query) ||
        data.module?.toString().includes(query) ||
        data.material?.toString().includes(query) ||
        data.test?.toString().includes(query) ||
        data.jobTitle?.toString().includes(query) ||
        data.email?.toString().includes(query) ||
        data.start_date?.toString().includes(query) ||
        data.end_date?.toString().includes(query) ||
        data.comment?.toString().includes(query) ||
        data.score?.toString().includes(query) ||
        data.numStaff?.toString().includes(query)
    );

    setFilteredData(filteredData);
    setCurrentPage(1);
  }, [query, data]);

  //pagination constants
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPage = (e) => {
    setitemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleUserSelect = (userId) => {
    selectedUser(userId);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">{tableName}</h5>
      </div>
      <div className="card-body">
        <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
          <div className="dataTable-top">
            <div className="dataTable-dropdown">
              <Form.Select
                id="itemsPerPage"
                className="dataTable-selector form-select"
                name="itemsPerPage"
                onChange={handleItemsPerPage}
              >
                <option value="5">5</option>
                <option value="8">8</option>
                <option value="10">10</option>
              </Form.Select>
              <label htmlFor="itemsPerPage">реда в таблица</label>
            </div>
            <div className="dataTable-search">
              <input
                className="dataTable-input"
                name="dataTable-input"
                placeholder="Търси..."
                type="text"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="dataTable-container">
            <table className="table table-striped" id="table1">
              <thead>
                <tr>
                  {tableHead?.map((data) => (
                    <th key={Math.random()}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentUsers?.map((person, _idx) => {
                  return (
                    person && (
                      <tr
                      key={_idx}
                      onClick={() => {
                        handleUserSelect(person.userId);
                        setSelectedRowIndex(_idx);
                      }}
                      className={
                        _idx === selectedRowIndex ? "selected-row" : ""
                      }
                    >
                      {!Object.values(person).every(value => !value) && Object.keys(person).map((key) => (
                        <td key={key}>{person[key] ? person[key] : "Липсват данни"}</td>
                      ))}
                    </tr>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="dataTable-bottom">
            {location === "/results" &&
            user.role_name.toLowerCase() === "staff member" ? (
              ""
            ) : (
              <ExportToExcel
                data={data}
                tableHead={tableHead}
                tableName={tableName}
              />
            )}
            <nav className="dataTable-pagination">
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber)}
              />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefferencesTable;
