import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import EditUserForm from "../../Forms/EditUserForm/EditUserForm";

function EditUserModal({ currentUserData, roles, job_titles, onChangeUser }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="success"
        size="sm"
        className="border-0"
        onClick={handleShow}
      >
        <i className="bi bi-pencil-square" /> Редактиране
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Редактиране на потребителски данни</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditUserForm
            currentUserData={currentUserData}
            onClose={handleClose}
            roles={roles}
            job_titles={job_titles}
            onChangeUser={onChangeUser}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditUserModal;
