import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { fetchData, postData } from "../../services/api";

import Form from "react-bootstrap/Form";

import {
  alertNotification,
  successNotification,
} from "../../services/notications";

import "./Test.scss";
import ResultModal from "./ResultModal";

const Test = () => {
  const [searchParams] = useSearchParams();
  const moduleId = searchParams.get("moduleId");
  const navigate = useNavigate();

  const [test, setTest] = useState({});
  const [answers, setAnswers] = useState([]);
  const [started, setStarted] = useState(false);
  const [hasEnd, setHasEnd] = useState(true);
  const [testFinished, setTestFinished] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [remainingTime, setRemainingTime] = useState({
    minutes: 0,
    seconds: 0,
  });

  let startTime = null;

  useEffect(() => {
    async function getTest() {
      try {
        const response = await fetchData(`/tests?moduleId=${moduleId}`);

        if (response.status !== 201) throw new Error();

        const test = response.data.data;

        if (test.test_result && test.test_result.length > 0) {
          alertNotification("Тестът вече е направен!");
          return navigate('/results');
        }

        if (!test.questions) {
          throw new Error();
        }

        setTest(test);

        let answers = [];

        test.questions.forEach((question) => {
          answers[question.question_id] = [];
        });

        setAnswers(answers);

        if (!test.end_date) {
          setHasEnd(false);
          return;
        }

        const remaing = Math.floor(
          (new Date(test.end_date).getTime() - new Date().getTime()) / 60000
        );
        if (remaing < 0) {
          throw new Error();
        }
      } catch (err) {
        // navigate('/')
      }
    }
    getTest();
  }, []);

  const startTest = () => {
    if (test.questions?.length === 0) {
      return alertNotification("Все още няма тест по този модул!");
    }
    if (new Date() < test.start_date) {
      return alertNotification("Тестът не може да бъде започнат!");
    } else if (new Date() > new Date(test.end_date) && hasEnd) {
      setStarted(false);
      return alertNotification("Тестът е приключил!");
    }

    setStarted(true);
    startTime = new Date().getTime();

    const timer = setInterval(() => {
      const currentTime = new Date().getTime();
      let timeDiff = 0;
      if (hasEnd) {
        const endTime = new Date(test.end_date).getTime();

        timeDiff = endTime - currentTime;

        if (timeDiff <= 0) {
          clearInterval(timer);
          setStarted(false);
          setRemainingTime({
            minutes: 0,
            seconds: 0,
          });
          return alertNotification("Тестът приключи!");
        }
      } else {
        timeDiff = currentTime - startTime;
      }

      const minutes = Math.floor(timeDiff / 60000);
      const seconds = Math.floor((timeDiff % 60000) / 1000);

      setRemainingTime({
        minutes,
        seconds,
      });
    }, 1000);
  };

  const changePage = (add) => {
    let newQuestion = currentQuestion;

    if (add && newQuestion + 1 <= test.questions.length - 1) {
      newQuestion++;
    } else if (!add && newQuestion - 1 >= 0) {
      newQuestion--;
    }

    setCurrentQuestion(newQuestion);
  };

  useEffect(() => {
    if (!test.questions) {
      return;
    }
    if (test.questions[currentQuestion].question_type === 3) {
      const textarea = document.querySelector("textarea");
      textarea.value = answers[test.questions[currentQuestion].question_id];
      return;
    }
    const answersDiv = document.querySelectorAll(".answer");
    const answersArray = answers[test.questions[currentQuestion].question_id];

    answersDiv.forEach((answer) => {
      answer.classList.remove("selected");
      if (answersArray.includes(answer.id)) {
        answer.classList.add("selected");
      }
    });
  }, [currentQuestion]);

  const selectAnswer = (e) => {
    let answersArray = answers[test.questions[currentQuestion].question_id];
    if (test.questions[currentQuestion].question_type === 1) {
      const answers = document.querySelectorAll(".answer");
      answers.forEach((answer) => {
        answer.classList.remove("selected");
        if (answer.id === e.target.id) {
          answersArray = [answer.id];
        }
      });
    }

    e.target.classList.toggle("selected");

    const isSelected = e.target.classList.contains("selected");
    const isSelectedAnswer = answersArray.includes(e.target.id);

    if (!isSelectedAnswer && isSelected) {
      answersArray.push(e.target.id);
    } else if (isSelectedAnswer && !isSelected) {
      answersArray.splice(answersArray.indexOf(e.target.id), 1);
    }

    setAnswers({
      ...answers,
      [test.questions[currentQuestion].question_id]: answersArray,
    });
  };

  const enterAnswers = (e) => {
    setAnswers({
      ...answers,
      [test.questions[currentQuestion].question_id]: e.target.value,
    });
  };

  const submitTest = async (e) => {
    e.preventDefault();

    let hasNoAnswered = false;

    for (const answer in answers) {
      if (answers.hasOwnProperty(answer)) {
        if (Array.isArray(answers[answer])) {
          const array = answers[answer];
          if (array.length === 0) {
            hasNoAnswered = true;
            break;
          }
        } else {
          if (answers[answer] === "") {
            hasNoAnswered = true;
            break;
          }
        }
      }
    }

    if (hasNoAnswered) {
      return alertNotification("Моля отговорете на всички въпроси!");
    }

    const answersToSend = formatAnswers(answers);
    const dateForDb = new Date().toISOString();
    const answer = {
      test_id: test.test_id,
      test_date: dateForDb,
      answers: answersToSend,
    };

    try {
      const response = await postData("/tests/pass", answer);
      if (response.status === 201) {
        // successNotification('Тестът е приключен успешно!');
        // navigate('/');
        const info = response.data && response.data.data;
        if (!info)
          alertNotification("Възникна грешка при приключването на теста!");
        setStarted(false);
        setResultInfo(info);
        setResultModalShow(true);
      } else {
        alertNotification("Възникна грешка при приключването на теста!");
      }
    } catch (err) { }
  };

  const formatAnswers = (answersObject) => {
    let answerToSave = {};

    for (const answer in answersObject) {
      if (answersObject.hasOwnProperty(answer)) {
        let indexes = [];
        if (Array.isArray(answersObject[answer])) {
          const array = answersObject[answer];
          for (const index in array) {
            indexes.push(Number(array[index]));
          }
        } else {
          indexes = answersObject[answer];
        }

        answerToSave[Number(answer)] = indexes;
      }
    }

    return answerToSave;
  };

  const [resultModalShow, setResultModalShow] = useState(false);
  const [resultInfo, setResultInfo] = useState({});

  const resultModalHide = () => {
    setResultModalShow(false);
  };

  return (
    <div className="container test-page">
      <ResultModal
        show={resultModalShow}
        close={resultModalHide}
        result={resultInfo}
      />
      <div className="header">
        {test ? (
          <span className="title">Тест по обучение "{test.module_name}"</span>
        ) : (
          ""
        )}
        {(started && !testFinished) ? (
          <div className="timer">
            <div className="icon-text">
              <i className="bi bi-clock" />
              <span className="title">
                {hasEnd ? "Оставащо време" : "Използвано време"}:
              </span>
            </div>
            <span className="time">
              {String(remainingTime.minutes).padStart(2, "0")}:
              {String(remainingTime.seconds).padStart(2, "0")}
            </span>
          </div>
        ) : (
          <button className="btn btn-success" onClick={() => { startTest(); }}>
            Стартирай теста
          </button>
        )}
      </div>
      {test.questions && (started && !testFinished) ? (
        <div className="content">
          <div className="question-header">
            <div className="question-title-header">
              <span className="question-title">
                {test.questions[currentQuestion].question_title}
              </span>
              <span className="question-type">
                {test.questions[currentQuestion].question_type === 1
                  ? "Един верен отговор"
                  : test.questions[currentQuestion].question_type === 2
                    ? "Няколко верни отговора"
                    : "Отворен отговор"}
              </span>
            </div>
            <span className="question-description">
              {test.questions[currentQuestion].question_description}
            </span>
          </div>
          <div className="answers">
            {test.questions[currentQuestion].question_type === 3 ? (
              <div>
                <Form.Control as="textarea" onChange={enterAnswers} rows={10} />
              </div>
            ) : (
              test.questions[currentQuestion].answers.map((answer) => (
                <div
                  className="answer"
                  key={answer.answer_id}
                  id={answer.answer_id}
                  onClick={selectAnswer}
                >
                  {answer.answer_text}
                </div>
              ))
            )}
          </div>
          <div className="actions">
            <button
              className="btn btn-primary"
              onClick={() => changePage(false)}
              disabled={currentQuestion === 0}
            >
              <i className="bi bi-arrow-left" />
            </button>
            <span className="page-label">
              {currentQuestion + 1}/{test.questions.length}
            </span>
            {currentQuestion === test.questions.length - 1 ? (
              <Link className="btn btn-success" onClick={submitTest}>
                Приключи
              </Link>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => changePage(true)}
              >
                <i className="bi bi-arrow-right" />
              </button>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Test;
