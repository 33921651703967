import { Link } from "react-router-dom";
import errorImg from "../../../assets/images/no-modules.webp";

const ModuleNotFound = () => {
  return (
    <div className="error-page container">
      <div className="col-md-8 col-12 offset-md-2">
        <div className="text-center">
          <img
            className="img-error"
            height="600vh"
            src={errorImg}
            alt="Not Found"
          />
          <p className="fs-5 text-gray-600">
            Няма намерени обучения. Моля, свържете се с администратора.
          </p>
          <Link to="/" className="btn btn-lg btn-primary mt-3">
            Към начало
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ModuleNotFound;
