import { useDispatch } from "react-redux";
import errorImg from "../../../assets/images/error-403.svg";
import { hideBar, showBar } from "../../../store/reducers/sidebar";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import "./ForbiddenPage.scss"

const ForbiddenPage = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(hideBar());

    return () => {
      dispatch(showBar());
    }
  })
  return (
    <div class="error-page ">
      <div class="col-md-8 col-12 offset-md-2">
        <div class="text-center">
          <img
            class="img-error"
            src={errorImg}
            alt="Forbidden"
          />
          <h1 class="error-title">Forbidden</h1>
          <p class="fs-5 text-gray-600">
            Нямате достъп до тази страница.
          </p>
          <Link to="/" class="btn btn-lg btn-primary mt-3" >Начало</Link>
        </div>
      </div>
    </div>
  );
};

export default ForbiddenPage;
