import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import arrow from "../../../assets/images/arrow.svg";

const Submenu = (props) => {
  const location = useLocation().pathname;
  const [status, ChangeStatus] = useState("closed");
  const pathnName = location.split("/")[1];

  useEffect(() => {
    let match = false;
    props.children.forEach((child) => {
      if (child.props.link === location) {
        match = true;
      }
    });
    if (match) {
      ChangeStatus("open");
    } else {
      ChangeStatus("closed");
    }
  }, []);

  return (
    <div className="submenu-item  mb-1 mt-1">
      <div
        className={
          pathnName === props.pathname
            ? "sidebar-link dropdown d-flex justify-content-between align-items-center active mb-1"
            : "sidebar-link dropdown d-flex justify-content-between align-items-center "
        }
        onClick={() => ChangeStatus(status === "closed" ? "open" : "closed")}
      >
        <div className="d-flex align-items-center">
          <i className={`bi bi-${props.icon} link-icon`} />
          <span>{props.title}</span>
        </div>
        <img src={arrow} alt="arrow img" className="arrow-icon"/>
      </div>
      <div className={`submenu submenu-${status}`}>{props.children}</div>
    </div>
  );
};

export default Submenu;
