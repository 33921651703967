import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import MaterialsAccordion from "../../MaterialsAccordion";
import CreateMaterialModal from "../../Modals/CreateMaterialModal/CreateMaterialModal";

import "sweetalert2/src/sweetalert2.scss";
import "./CreateMaterials.scss";

const CreateMaterials = ({ moduleData, methods, isEdit }) => {
  // states
  const [allMaterials, setAllMaterials] = useState(moduleData.materials);

  useEffect(() => {
    methods.onUpdateMaterials(allMaterials);
  }, [allMaterials]);

  const handleSaveNewMaterial = (newData) => {
    setAllMaterials([...allMaterials, newData]);
  };

  const handleDeleteMaterial = (id) => {
    setAllMaterials((allMaterials) =>
      allMaterials.filter((material) => material.material_id !== id)
    );
  };

  const handleEditMaterial = (newValue) => {
    setAllMaterials((allMaterials) =>
      allMaterials.map((material) =>
        material.material_id === newValue.material_id
          ? { ...material, ...newValue }
          : material
      )
    );
  };

  const handlePrevPage = () => {
    methods.onUpdateMaterials(allMaterials);
    methods.onPrevPage();
  };

  const handleSaveModule = () => {
    isEdit ? methods.onEditModule() : methods.onSaveMaterials();
  };


  return (
    <>
      <div className="form-header">
        <h4>Материали</h4>
      </div>
      <CreateMaterialModal saveMaterial={handleSaveNewMaterial} />
      <MaterialsAccordion
        allMaterials={allMaterials}
        onDeleteItem={handleDeleteMaterial}
        onEditMaterial={handleEditMaterial}
      />
      <div className="form-footer">
        <Button className="form-buttons" onClick={handlePrevPage}>
          Назад
        </Button>
        <Button onClick={handleSaveModule}>Запази</Button>
      </div>
    </>
  );
};

export default CreateMaterials;
