import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import EditMaterial from "../../Forms/EditMaterial";

function EditMaterialModal({ material, onEditMaterial, materialId }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <i className="far fa-pen-to-square me-1 fa-lg accordion-icon" onClick={handleShow} />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Редактиране на материал
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditMaterial onClose={handleClose} material={material} onEditMaterial={onEditMaterial} materialId={materialId} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditMaterialModal;
