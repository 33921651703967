import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { fetchData, postData } from "../../services/api";

import Swal from "sweetalert2/dist/sweetalert2.js";

import { Button, Form } from "react-bootstrap";

const TestEvaluate = () => {
    const [searchParams] = useSearchParams();
    const test_result_id = searchParams.get("testResultId");
    const navigate = useNavigate();

    const [testData, setTestData] = useState({})
    const [testQuestions, setTestQuestions] = useState({});

    const [currentQuestion, setCurrentQuestion] = useState(-1);

    const [evaluatedQuestions, setEvaluatedQuestions] = useState([])

    const isNumber = (str) => {
        return !isNaN(parseInt(str)) && isFinite(str);
    }

    const getGrade = async () => {
        const { value: grade } = await Swal.fire({
            title: "Оценяване",
            input: "text",
            inputLabel: "Въведете оценка от 2 до 6",
            inputPlaceholder: "Въведете оценка",
            showCancelButton: true,
        })
    
        if(!grade) return;

        if(!isNumber(grade) || Number(grade) < 2 || Number(grade) > 6) {      
            Swal.fire({
                title: "Неуспешно оценяне",
                icon: "error",
                text: "Моля въведете оценка от 2 до 6",
            });
            return;
        }
        return grade;
    }

    const evaluateQuestion = async () => {
        const grade = await getGrade();
        if(!grade) return;
        
        Swal.fire({
            title: "Успешно оценяване",
            icon: "success",
            text: `Успешно оценихте въпрос №${currentQuestion+1} с оценка ${grade}`,
        });

        if(evaluatedQuestions.find(x=>x.question_id === testQuestions[currentQuestion].question_id)) {
            let buff_questions = evaluatedQuestions;
            const idx = buff_questions.findIndex(x=>x.question_id === testQuestions[currentQuestion].question_id)
            if(idx === -1) return;
            buff_questions[idx] = {
                ...buff_questions[idx],
                grade: grade
            }
            setEvaluatedQuestions([...buff_questions])
            return;
        }

        setEvaluatedQuestions([
            ...evaluatedQuestions,
            {
                question_id: testQuestions[currentQuestion].question_id,
                grade: grade
            }
        ])
    }

    useEffect(() => {
        async function getTestResult() {
            try {
                const response = await fetchData(`/tests/evaluate/result?test_result_id=${test_result_id}`)
                
                if (response.status !== 201 || !response.data || !response.data.data) {
                    throw new Error();
                }

                const { questions_result, ...testData} = response.data.data

                if(testData.evaluated) navigate('/results')

                setTestData(testData);
                setTestQuestions(questions_result);
                setCurrentQuestion(0);
            } catch (err) {
                console.log(err);
                navigate('/results')
            }
        }

        getTestResult();
    }, [])

    useEffect(() => {
        if(!test_result_id) navigate('/results');
    }, [])

    const getAnswerClass = (answerId) => {
        const answer =
            testQuestions[currentQuestion].user_answer.includes(answerId);
        return answer ? " selected" : "";
    };

    const changePage = (next) => {
        const newPage = next ? currentQuestion + 1 : currentQuestion - 1;
        if (newPage >= 0 && newPage < testQuestions.length) {
            setCurrentQuestion(newPage);
        }
    };

    const getGradeText = () => {
        if(testQuestions[currentQuestion].score >= 0) {
            return testQuestions[currentQuestion].score + " точки";
        }else {
            let question = evaluatedQuestions.find(x=>x.question_id === testQuestions[currentQuestion].question_id);
            if(!question) return "Няма"
            else return question.grade
        }
    }

    const postEvaluate = async () => {
        try {
            const response = await postData('/tests/evaluate', { test_result_id: test_result_id, evaluated_questions: evaluatedQuestions });
            if(response.status !== 201) throw new Error();
        } catch (err) {
            console.log(err);
            Swal.fire({
                title: "Неуспешно оценяне",
                icon: "error",
                text: "Нещо се обърка",
            });
        }
    }
    
    const submitEvaluate = async () => {
        const opened_questions_count = testQuestions.filter(question => question.question_type === 3).length;
        if(evaluatedQuestions.length !== opened_questions_count) {
            Swal.fire({
                title: "Неуспешно оценяне",
                icon: "error",
                text: "Моля въведете оценете отворените",
            });
            return;
        } else {
            Swal.fire({
                title: "Сигурни ли сте?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Отказ",
                confirmButtonText: "Запази",
            }).then(async (res) => {
                if (!res.isConfirmed) return;
                await postEvaluate();
                Swal.fire({
                    title: "Успешно оценяване",
                    icon: "success",
                    text: `Успешно оценихте теста на ${testData.first_name} ${testData.last_name}`,
                }).then(() => {
                    navigate('/results')
                });
            });
        }
    }

    return (
        <div className="container test-result-page">
            {testQuestions && testQuestions[currentQuestion] ? (
                <div className="content">
                    <div className="question-header">
                        <div className="question-title-header">
                            <span className="question-title">
                                {testQuestions[currentQuestion].question_title}
                            </span>
                            <span className="question-type">
                                {testQuestions[currentQuestion].question_type === 1
                                    ? "Един верен отговор"
                                    : testQuestions[currentQuestion].question_type === 2
                                        ? "Няколко верни отговора"
                                        : "Отворен отговор"}
                            </span>
                        </div>
                        <span className="question-description">
                            {testQuestions[currentQuestion].question_description}
                        </span>
                    </div>
                    <div className="answers">
                        {
                            testQuestions[currentQuestion].question_type === 3 ? (
                                <div className="text-answer">
                                    <Form.Control
                                        as="textarea"
                                        rows={10}
                                        readOnly
                                        value={testQuestions[currentQuestion].user_answer}
                                    />
                                    <span className="grade">
                                        Резултат:{" " + getGradeText()}
                                    </span>
                                </div>
                            ) : (
                                testQuestions[currentQuestion].answers.map((answer) => (
                                    <div
                                        className={"answer" + getAnswerClass(answer.answer_id)}
                                        key={answer.answer_id}
                                        id={answer.answer_id}
                                    >
                                        <div className="answer-text">{answer.answer_text}</div>
                                        {answer.iscorrect ? (
                                            <i className="fa fa-check correct" />
                                        ) : (
                                            <i className="fa fa-times incorrect" />
                                        )}
                                    </div>
                                ))
                            )
                        }
                    </div>
                    <div className="actions">
                        <button
                            className="btn btn-primary"
                            disabled={currentQuestion === 0}
                            onClick={() => changePage(false)}
                        >
                            Предишен
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={currentQuestion === testQuestions.length - 1}
                            onClick={() => changePage(true)}
                        >
                            Следващ
                        </button>
                        {
                            testQuestions[currentQuestion].question_type === 3 && (
                                (evaluatedQuestions.find(x => x.question_id === testQuestions[currentQuestion].question_id)) ? (
                                    <Button variant="danger" onClick={evaluateQuestion}>Промени оценка</Button>
                                ) : (
                                    <Button variant="warning" onClick={evaluateQuestion}>Оцени</Button>
                                )
                            )
                        }
                        {currentQuestion === testQuestions.length - 1 ? (
                            <Button variant="success" onClick={submitEvaluate}>Запази</Button>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    )
}

export default TestEvaluate;