import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { fetchData } from "../../../services/api";

import ResultsTable from "../../../components/Tables/ResultsTable";

const ManagerView = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getUsers() {
      try {
        const response = await fetchData(`/users/results`);
        if (response.status === 201) throw new Error();
        setTableData(response.data.data);
      } catch (error) {
        navigate("/results");
      }
    }

    getUsers();
  }, []);

  return (
    <div>
      <ResultsTable
        head={[
          "Модул",
          "Статус",
          "Дата",
          "Оценка",
          "",
        ]}
        data={tableData}
      />
    </div>
  );
};

export default ManagerView;
