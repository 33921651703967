import { createSlice } from "@reduxjs/toolkit";


export const sidebarSlice = createSlice({
  name: "sliderStatus",
  initialState: {
    status: "",
  },

  reducers: {
    showBar: (state) => {
      state.status = "active";
    },
    hideBar: (state) => {
      state.status = "inactive";
    },

    toggleSidebar: (state) => {

      if (state.status === "inactive" || state.status === "" ) {
        state.status = "active";
      } else {
        state.status = "inactive";
      }
      // state.status = "inactive" ? state.status = "active" : state.status = "asd"
    },
  },
});

export const { showBar, hideBar, toggleSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
