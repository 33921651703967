import React, { useState } from "react"; // Import useState
import { InputGroup } from "react-bootstrap";
import { MDBIcon } from "mdb-react-ui-kit";

import UserCard from "./UserCard";

const SearchChat = ({ rooms, allRooms, selectedRoom, openNewRoom }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]); // Store search results

  const search = () => {
    setSearchResults(allRooms?.filter(room => room?.first_name.toLowerCase().includes(searchValue.toLowerCase()) || room?.last_name.toLowerCase().includes(searchValue.toLowerCase())));
  }

  const openRoom = (room) => {
    const idx = rooms.findIndex(r => r.id === room.id);
    if (idx !== -1) {
      selectedRoom.set(rooms[idx].room_id);
      setSearchValue('');
      return;
    }
    setSearchValue('');
    openNewRoom(room)
  }

  return (
    <div className="mb-4 mb-md-0 p-3 chat-navigation">
      <InputGroup className="chat-search rounded mb-3">
        <input
          className="searchInput form-control"
          placeholder="Търси..."
          type="search"
          value={searchValue} // Bind state to input value
          onChange={(e) => {
            setSearchValue(e.target.value)
            search()
          }} // Update state on change
        />
        <button className="input-group-text border-0" id="search-addon">
          <MDBIcon fas icon="search" />
        </button>
      </InputGroup>

      {searchValue && (
        <div className="dropdown-menu show shadow bg-white rounded">
          {searchResults?.length ? searchResults.map((room, index) => (
            <div key={index} className="dropdown-item d-flex align-items-center" onClick={() => { openRoom(room) }}>
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                alt="avatar"
                className="d-flex align-self-center me-3"
                width="24"
              />
              {room.first_name}
            </div>
          )) : <span className="p-3">Няма намерени резултати</span>}
        </div>
      )}

      <ul className="mb-0 list-unstyled">
        {rooms && rooms.map((room, index) => (
          <UserCard
            room={room}
            selectRoom={selectedRoom.set}
            selectedRoom={selectedRoom.get}
            key={index}
          />
        ))}
      </ul>
    </div>
  );
};

export default SearchChat;