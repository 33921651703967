import { Link } from "react-router-dom";
import errorImg from "../../../assets/images/no-modules.webp";

const NoModules = () => {
  return (
    <div className="error-page container">
      <div className="col-md-8 col-12 offset-md-2">
        <div className="text-center">
          <img
            className="img-error"
            height="600vh"
            src={errorImg}
            alt="Not Found"
          />
          <p className="fs-5 text-gray-600">
            Няма намерени обучения. Моля, създайте модул.          </p>
          <Link to="/admin/manage-modules/create-module" className="btn btn-lg btn-success mt-3">
            Създай модул
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoModules;
