
const UserCard = ({ room, selectRoom, selectedRoom }) => {
  return (
    <li className={"p-2 mb-2 user-card " + (selectedRoom === room.room_id ? " active": '')} onClick={() => selectRoom(room.room_id)}>
      <a className="d-flex justify-content-between">
        <div className="d-flex flex-row">
          <div>
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
              alt="avatar"
              className="d-flex align-self-center me-3"
              width="60"
            />
            <span className="badge bg-success badge-dot"></span>
          </div>
          <div className="pt-1">
            <p className="fw-bold mb-0 name">{room.first_name} {room.last_name}</p>
            <p className="small text-muted last-message">
              {room.last_message ? room.last_message : "Няма съобщения"}
            </p>
          </div>
        </div>
        {
          /*
          <div className="pt-1">
          <p className="small text-muted mb-1">Just now</p>
          <span className="badge bg-danger rounded-pill float-end">
            3
          </span>
          </div>
          */
        }
      </a>
    </li>
  )
}

export default UserCard