import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { fetchData } from "../../../services/api";

import RefferencesTable from "../../../components/Tables/RefferencesTable";
import BarChart from "../../../components/Charts/BarChart";

const EmployeesInfo = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getStaff() {
      try {
        const response = await fetchData(`/users/references-staff-members`);
        if (response.status === 204) throw new Error();

        if (response.data.data.length > 1) {
          const mappedData = response.data.data.map((user) => ({
            name: (user.first_name + ' ' + user.last_name),
            jobTitle: user.job_title_name || "Няма",
            email: user.email,
          }));
          setTableData(mappedData);
        } else {
          setTableData(response.data.data);
        }
      } catch (error) {
        navigate("/references-staff-members");
      }
    }

    getStaff();
  }, []);

  const jobTitles = [...new Set(tableData.map((data) => data.jobTitle))];

  const usersPerModule = jobTitles.map((jobTitle) =>
    tableData.filter((data) => data.jobTitle === jobTitle).length
  );

  const barColors = ['blue', 'lightblue'];

  const chartDataBar = {
    labels: jobTitles,
    datasets: [
      {
        label: 'Служители по длъжност',
        data: usersPerModule,
        backgroundColor: barColors,
      },
    ],
  };

  const chartOptionsBar = {
    scales: {
      y: {
        beginAtZero: true,
      },
      stepSize: 1,
    },
  };

  return (
    <div className="staff-info row">
      <div className="col-12">
        <div className="staff-members">
          <RefferencesTable
            tableName="Активни служители:"
            tableHead={["Име", "Длъжност", "Имейл"]}
            tableData={tableData}
          />
          <div className="col-md-6">
            <BarChart chartName="Оценки на служители"
              chartData={chartDataBar}
              charOptions={chartOptionsBar} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeesInfo;
